@tailwind components;
@tailwind utilities;

@layer components {
  .jr-btn-small {
    @apply inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm cursor-pointer;
  }

  .jr-btn-md {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm cursor-pointer;
  }

  .jr-btn-4xl {
    font-size: 2.9rem;
    line-height: 3.2rem;
    @apply inline-flex items-center px-12 py-6 border border-transparent font-medium rounded-md shadow-sm cursor-pointer;
  }

  .jr-btn-primary {
    @apply text-jpbeige bg-jpprimary hover:bg-jpprimary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-jpprimary/80;
  }

  .jr-btn-secondary {
    @apply text-jpbeige bg-jporange hover:bg-jporange/80;
  }

  .jr-btn {
    @apply inline-flex items-center px-6 py-3 border-transparent text-base font-medium rounded-md shadow-sm cursor-pointer text-white bg-jpprimary hover:bg-jpprimary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-jpprimary/80;
  }
}

.font-unbounded {
  font-family: Unbounded, sans-serif;
}

.client-logo-style {
  // opacity: 0.5;
  filter: brightness(0) invert(1);
  filter: grayscale(100%);
}

.faq-title h2 {
  @apply font-["Unbounded"];
  font-size: 4rem !important;
  margin-bottom: 2rem !important;
}
.faq-row {
  @apply font-["Unbounded"];
  color: white !important;
  margin-block: 1rem;
}

.row-title {
  color: white !important;
  & span {
    color: white !important;
    font-size: 2rem;
  }
}

.row-content-text {
  color: white !important;
}

.interactive-banners-image {
  width: 350px;
  height: 240px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

@media (max-width: 1190px) {
  .interactive-banners-image {
    width: 500px;
    height: 350px;
  }
}
@media (max-width: 768px) {
  .interactive-banners-image {
    width: 100%;
    height: 350px;
  }
}

.interactive-banners-text-content {
  & div {
    & span {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .btn-services {
    font-size: 18px !important;
  }
}

.icons_about {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

// Blog Styles
// Article COLS
.article-col-right {
  display: flex;
  flex-direction: row;
  width: 100%;

  & .article-content {
    padding: 48px !important;
    width: 60%;
  }

  & .article-img {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .article-col-right {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;

    & .article-content {
      width: 100%;
      padding: 20px !important;
    }

    & .article-img {
      width: 110%;
      padding: 0;
    }
  }
}

.article-col-left {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  & .article-content {
    padding: 48px !important;
    width: 60%;
  }

  & .article-img {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .article-col-left {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    overflow: hidden;

    & .article-content {
      width: 100%;
      padding: 20px !important;
    }

    & .article-img {
      width: 110%;
      padding: 0;
    }
  }
}

// Article ROWS
.article-row-left {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;

  & .article-row-left-title {
    padding: 45px 15px 30px 10px;
    border: 1px solid #e7e86f;
    border-left: none;
    width: fit-content;
  }

  & .article-row-left-content {
    padding-right: 100px;
  }
}

@media screen and (max-width: 768px) {
  .article-row-left {
    padding-inline: 10px;
    & .article-row-left-content {
      padding-right: 30px;
    }
  }
}

.article-row-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 50px;
  width: 100%;

  & .article-row-right-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 45px 15px 30px 10px;
    border: 1px solid #e7e86f;
    border-right: none;
    width: 1140px;
  }

  & .article-row-right-content {
    padding-right: 100px;
  }
}

@media screen and (max-width: 768px) {
  .article-row-right {
    padding-inline: 10px;
    & .article-row-right-content {
      padding-right: 30px;
    }
  }
}

// Article Conclusion
.article-conclusion {
  padding: 50px;

  & .article-conclusion-content {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .article-conclusion {
    & .article-conclusion-content {
      width: 100%;
    }
  }
}
