@import 'swiper/swiper-bundle.min.css';
@import "bootstrap/dist/css/bootstrap.min.css";
@import "atropos/atropos.css";
@import "react-18-image-lightbox/style.css";
@tailwind base;
/* Vendors */
/* Variable */
/* Transition */
:root {
  --default-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  --alt-font: "Unbounded", sans-serif;
  --primary-font: "Figtree", sans-serif;
  --viewport-height: 100vh;
}

/* Core */
.collapse {
  visibility: visible !important;
}

::selection {
  color: #000;
  background: #dbdbdb;
}

::-moz-selection {
  color: #a6a6a6;
  background: #dbdbdb;
}

html {
  @apply text-[14px] lg:text-[13px] md:text-[12px] sm:text-[11px];
  scroll-behavior: inherit !important;
}

body {
  @apply font-sans text-base font-normal;
  color: #828282;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}

a {
  transition: var(--default-transition);
}

button:focus,
.btn-close:focus,
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.container,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1199px) {
  .container,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    max-width: 1170px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row.gx-0, .row.g-0 {
  margin-left: 0;
  margin-right: 0;
}
.row.gx-0 > *, .row.g-0 > * {
  padding-left: 0;
  padding-right: 0;
}

.cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-position: center center;
}

iframe {
  width: 100%;
}

.error iframe {
  border: 1px solid red;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

input,
textarea {
  line-height: 1;
}
input:focus,
textarea:focus {
  outline: none;
}
input.errors-danger,
textarea.errors-danger {
  @apply border-error;
}
input.errors-danger[type=checkbox],
textarea.errors-danger[type=checkbox] {
  box-shadow: 0 0 0 1px rgb(255, 0, 0), 1px 1px 0 0 rgb(255, 0, 0);
}
input:-webkit-autofill,
input -webkit-autofill:hover,
input -webkit-autofill:focus,
input -webkit-autofill:active,
textarea:-webkit-autofill,
textarea -webkit-autofill:hover,
textarea -webkit-autofill:focus,
textarea -webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.checkbox-with-label {
  @apply inline-block;
}
.checkbox-with-label [type=checkbox] {
  @apply absolute opacity-0 top-0 left-0;
}
.checkbox-with-label [type=checkbox]:checked + span::after {
  @apply absolute top-0 left-0 h-full w-full text-center text-xs leading-[15px] font-black;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
}

.fit-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.fit-video iframe,
.fit-video video {
  @apply w-full h-full absolute top-0 left-0;
}

.bg-fit-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.bg-fit-video iframe,
.bg-fit-video video {
  @apply w-full h-full absolute top-0 mob:-top-48 left-0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  @apply fixed;
  z-index: 1042;
}
.ReactModalPortal .ril__outer {
  background-color: rgba(35, 35, 35, 0.9);
}
.ReactModalPortal .ril__inner {
  padding: 60px 0;
}
@media (orientation: landscape) {
  .ReactModalPortal .ril__inner .ril__image {
    max-height: 100%;
  }
  @screen md {
    .ReactModalPortal .ril__inner .ril__image {
      max-height: calc(100% + 300px);
    }
  }
}
@media (orientation: portrait) {
  @screen md {
    .ReactModalPortal .ril__inner .ril__image {
      max-height: inherit;
    }
  }
}
.ReactModalPortal .ril__toolbar {
  background-color: transparent;
}
.ReactModalPortal .ril__toolbar .ril__closeButton {
  margin: 30px 0 30px 0;
  background: none;
  font-family: "feather" !important;
  opacity: 1;
}
.ReactModalPortal .ril__toolbar .ril__closeButton:before {
  content: "\ea02";
  color: #fff;
  font-size: 25px;
}
.ReactModalPortal .ril__toolbar .ril__zoomInButton,
.ReactModalPortal .ril__toolbar .ril__zoomOutButton {
  @apply hidden;
}
.ReactModalPortal .ril__navButtonPrev,
.ReactModalPortal .ril__navButtonNext {
  background: none;
  background-color: #232323;
  font-family: "feather" !important;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
@screen sm {
  .ReactModalPortal .ril__navButtonPrev,
  .ReactModalPortal .ril__navButtonNext {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
.ReactModalPortal .ril__navButtonPrev:before,
.ReactModalPortal .ril__navButtonNext:before {
  @apply flex justify-center text-white;
  opacity: 0.7;
  font-size: 22px;
}
@screen sm {
  .ReactModalPortal .ril__navButtonPrev:before,
  .ReactModalPortal .ril__navButtonNext:before {
    font-size: 18px;
  }
}
.ReactModalPortal .ril__navButtonPrev {
  left: 40px;
}
.ReactModalPortal .ril__navButtonPrev:before {
  content: "\e910";
}
.ReactModalPortal .ril__navButtonPrev:before:hover {
  color: #fff;
}
.ReactModalPortal .ril__navButtonNext {
  right: 40px;
}
.ReactModalPortal .ril__navButtonNext:before {
  content: "\e912";
}
.ReactModalPortal .ril__captionContent {
  @apply text-xs;
  color: #f3f3f3;
}

.up-down-ani {
  @apply relative mx-auto;
  animation-name: down-arrow;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-delay: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: var(--default-transition);
}

.swiper-slide {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-auto-slide .swiper-slide {
  width: auto;
}

.white-move .swiper-slide,
.white-move .swiper-wrapper {
  cursor: url(../img/icon-move-light.png), move;
}

.black-move .swiper-slide,
.black-move .swiper-wrapper {
  cursor: url(../img/icon-move-black.png), move;
}

.vertical-white-move .swiper-slide,
.vertical-white-move .swiper-wrapper {
  cursor: url(../img/vertical-icon-move-light.png), move;
}

.vertical-black-move .swiper-slide,
.vertical-black-move .swiper-wrapper {
  cursor: url(../img/vertical-icon-move-black.png), move;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 45px;
  @apply xs:bottom-[30px];
}

.swiper-pagination-light {
  --swiper-pagination-color: #ffffff;
}

.swiper-pagination-dark {
  --swiper-pagination-color: #232323;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  @apply bg-transparent align-middle rounded-full inline-block h-3 my-auto mx-[7px] w-3 border-2 border-solid outline-none;
  border-color: var(--swiper-pagination-color);
  width: var(--swiper-pagination-width);
  height: var(--swiper-pagination-height);
  transition: var(--default-transition);
  --swiper-pagination-bullet-horizontal-gap: 6px;
}
.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-color);
}

.swiper-pagination-fractional .swiper-pagination-bullet {
  @apply rounded-none bg-transparent border-none text-lg text-darkgray font-serif font-medium;
  width: 25px;
  height: 20px;
  margin-right: 20px !important;
  text-align: center;
  color: #232323;
  opacity: 0.5;
  transition: var(--default-transition);
}
.swiper-pagination-fractional .swiper-pagination-bullet:hover {
  opacity: 1;
}
.swiper-pagination-fractional .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply rounded-none bg-transparent border-none underline underline-offset-4 font-serif opacity-100;
}

.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Swiper pagination medium */
.swiper-pagination-medium {
  --swiper-pagination-width: 10px;
  --swiper-pagination-height: 10px;
  --swiper-pagination-active-width: 16px;
  --swiper-pagination-active-height: 16px;
}

.swiper-pagination-large {
  --swiper-pagination-width: 12px;
  --swiper-pagination-height: 12px;
  --swiper-pagination-active-width: 16px;
  --swiper-pagination-active-height: 16px;
}

.swiper-pagination-big .swiper-pagination-bullet {
  --swiper-pagination-width: 14px;
  --swiper-pagination-height: 14px;
}

/* Swiper Pagination Style 01 */
.swiper-pagination-01 .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 10px 5px;
  @apply opacity-100;
  background-color: var(--swiper-pagination-color);
  border-color: var(--swiper-pagination-color);
}
.swiper-pagination-01 .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-transparent w-[16px] h-[16px];
}

/* Swiper Pagination Style 02 */
.swiper-pagination-02 .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply opacity-30;
  background-color: var(--swiper-pagination-color);
  border-color: var(--swiper-pagination-color);
}
.swiper-pagination-02 .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply opacity-100;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: "Feather";
  font-size: 15px;
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-prev:after {
  content: "\e910";
}

.swiper-button-next {
  right: 0;
}
.swiper-button-next:after {
  content: "\e912";
}

.swiper-navigation-light .swiper-button-next,
.swiper-navigation-light .swiper-button-prev {
  --swiper-navigation-background: #fff;
  --swiper-navigation-color: #232323;
}

.swiper-navigation-dark .swiper-button-next,
.swiper-navigation-dark .swiper-button-prev {
  --swiper-navigation-background: #232323;
  --swiper-navigation-color: #fff;
}

/* slider navigation style 01 */
.swiper-navigation-01 .swiper-button-next,
.swiper-navigation-01 .swiper-button-prev {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px];
}
.swiper-navigation-01 .swiper-button-next:hover,
.swiper-navigation-01 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-color);
  color: var(--swiper-navigation-background);
}

/* slider navigation style 02 */
.swiper-navigation-02 .swiper-button-next,
.swiper-navigation-02 .swiper-button-prev {
  background-color: transparent;
  border: 2px solid var(--swiper-navigation-background);
  color: var(--swiper-navigation-background);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[45px] h-[45px] leading-[45px] rounded-full;
}
.swiper-navigation-02 .swiper-button-next:hover,
.swiper-navigation-02 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  border-color: transparent;
}
.swiper-navigation-02.swiper-navigation-light .swiper-button-next,
.swiper-navigation-02.swiper-navigation-light .swiper-button-prev {
  border-color: rgba(255, 255, 255, 0.3);
}

/* slider navigation style 03 */
.swiper-navigation-03 .swiper-button-next,
.swiper-navigation-03 .swiper-button-prev {
  margin-top: 0;
  background-color: transparent;
  border: 1px solid;
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px] rounded-full border-mediumgray;
}
.swiper-navigation-03 .swiper-button-next:hover,
.swiper-navigation-03 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: transparent;
}

/* slider navigation style 04 */
.swiper-navigation-04 .swiper-button-next,
.swiper-navigation-04 .swiper-button-prev {
  margin: 0;
  transform: translateY(-50%);
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  box-shadow: 0 0 35px rgba(40, 42, 54, 0.18);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px] rounded-full;
}
.swiper-navigation-04 .swiper-button-next:hover,
.swiper-navigation-04 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-color);
  color: var(--swiper-navigation-background);
}

.swiper-default-nav-hidden > .swiper-button-prev,
.swiper-default-nav-hidden > .swiper-button-next {
  @apply hidden;
}

@screen xl {
  .xl-nav-hidden > .swiper-button-prev,
  .xl-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen lg {
  .lg-nav-hidden > .swiper-button-prev,
  .lg-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen md {
  .md-nav-hidden > .swiper-button-prev,
  .md-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen sm {
  .sm-nav-hidden > .swiper-button-prev,
  .sm-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen xs {
  .xs-nav-hidden > .swiper-button-prev,
  .xs-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

.content-box-image {
  height: 33.3333333333%;
}

.personalportfolio .menu-list-wrapper.menu-list-wrapper-small {
  max-height: calc(65vh - 100px);
}
.personalportfolio .menu-list-wrapper {
  overflow: auto;
  height: calc(100vh - 150px);
}
.personalportfolio .menu-list-item {
  clear: both;
  position: relative;
  list-style: none;
  padding: 13px 0;
}
.personalportfolio .menu-list-item a {
  font-size: 44px;
  line-height: 50px;
  position: relative;
  padding: 0;
  color: #fff;
  display: inline-block;
}

/* svg drawing path */
.drawing-animation path,
.drawing-animation circle,
.drawing-animation rect,
.drawing-animation polygon,
.drawing-animation ellipse {
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 20000;
  stroke-dashoffset: 20000;
  -webkit-animation: drawing-animation 6s linear forwards;
  animation: drawing-animation 6s linear forwards;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@keyframes drawing-animation {
  to {
    stroke-dashoffset: 0;
  }
}
/* Tabs */
.switch-tabs .nav-tabs {
  border-top: 1px solid #dee2e6;
}
.switch-tabs .nav-tabs .nav-link {
  padding: 30px 70px;
  border-radius: 0;
  border: none;
  background: transparent;
  position: relative;
  font-weight: 600;
  color: gray;
}
.switch-tabs .nav-tabs .nav-link:focus {
  box-shadow: none;
  outline: none;
}
.switch-tabs .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background: var(--base-color);
  transition: var(--default-transition);
}
.switch-tabs .nav-tabs .nav-link.active {
  color: var(--base-color);
}
.switch-tabs .nav-tabs .nav-link.active:after {
  width: 100%;
}

button:focus-visible {
  box-shadow: none;
  outline: none;
}

.typed-cursor {
  @apply hidden;
}

.tag-cloud a {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 5px 18px 6px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  margin-right: 15px;
}
.tag-cloud a:last-child {
  margin-right: 0;
}
.tag-cloud a:hover {
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.1);
  color: #828282;
}

.btn-reply {
  float: right;
  font-size: 11px;
  line-height: initial;
  padding: 7px 16px 6px;
  border-radius: 50px;
  border: 1px solid rgba(44, 44, 44, 0.25);
  background-color: #fff;
}
.btn-reply:hover {
  border-color: #0038e3;
  background: #0038e3;
  color: #fff;
}

.overlap-section {
  margin-top: var(--overlapheight);
}

.full-screen {
  height: calc(var(--viewport-height) - var(--header-height));
}

@screen xs {
  .xs\:full-screen {
    height: calc(var(--viewport-height) - var(--header-height)) !important;
  }
}

/* React Custom Scrollbar */
.smooth-scrollbar {
  height: 100%;
}
.smooth-scrollbar .scroll-content {
  padding-right: 15px;
  width: 100%;
}
.smooth-scrollbar .scrollbar-track-y {
  right: 8px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1490196078);
}
.smooth-scrollbar .scrollbar-thumb {
  left: -1px;
  width: 4px;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7490196078);
}
.smooth-scrollbar.dark .scrollbar-track-y {
  background-color: rgba(0, 0, 0, 0.1490196078);
}
.smooth-scrollbar.dark .scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7490196078);
}
.smooth-scrollbar.light .scrollbar-track-y {
  background-color: #939393;
}
.smooth-scrollbar.light .scrollbar-thumb {
  background-color: #e4e4e4;
}

/* fancy box background */
.fancy-box-background {
  position: relative;
  z-index: 1;
}

.bg-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  visibility: hidden;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-size: cover;
}

.fancy-box-background .opacity-medium {
  z-index: -1;
}

.fancy-box-background .fancy-box-item {
  position: static;
}

.fancy-box-background.fancy-box-col .fancy-box-item {
  position: relative;
}

.fancy-box-background .fancy-box-item:hover .bg-banner-image {
  opacity: 1;
  visibility: visible;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.fancy-box-background .fancy-box-item:hover .light,
.fancy-box-background .fancy-box-item:hover .light i {
  color: #fff;
}

.fancy-box-background .fancy-box-item:hover .dark {
  color: #232323;
}

.fancy-box-background:after {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  position: absolute;
}

.fancy-box-background .fancy-text-box li:hover {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
}

.fancy-box-background .fancy-text-box li:hover span {
  color: rgba(255, 255, 255, 0.9);
}

.fancy-box-background .fancy-text-box li:hover a {
  color: rgba(255, 255, 255, 0.9);
}

.feature-box-main {
  background: #fff;
  transition: var(--default-transition);
}
.feature-box-main:hover {
  @apply bg-darkgray;
}
.feature-box-main:hover .feature-box-content {
  color: white;
}
.feature-box-main:hover span {
  color: white;
}
.feature-box-main:hover a {
  color: white;
}

/* Custom Modal */
.modal-wrapper .inner-modal {
  max-width: 100%;
  perspective: 600px;
}
.modal-wrapper .inner-modal > div {
  max-width: 100%;
}
.modal-wrapper .btn-close {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 30px;
  font-family: Figtree, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: white;
  opacity: 1;
}

/* Custom React Modal */
.ReactModalPortal.custom_modal {
  @apply opacity-0 invisible;
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal.open {
  @apply opacity-100 visible;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content {
  opacity: 1;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.fadeIn .modal-inner {
  opacity: 1;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.fade-zoom .modal-inner {
  opacity: 1;
  transform: scale(1);
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.rotate-zoom .modal-inner {
  opacity: 1;
  transform: rotateX(0deg);
}
.ReactModalPortal.custom_modal .ReactModal__Content {
  inset: 50% auto auto 50% !important;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 1;
}
.ReactModalPortal.custom_modal .ReactModal__Content.fadeIn .modal-inner {
  opacity: 0;
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal .ReactModal__Content.fade-zoom .modal-inner {
  opacity: 0;
  transform: scale(0.5);
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal .ReactModal__Content.rotate-zoom .modal-inner {
  opacity: 0;
  transform: rotateX(60deg) perspective(600px);
  transition: var(--default-transition);
}

.all-demo-btn-section {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  width: 655px;
  z-index: 70;
  transform: translateX(calc(100% + 15px));
  transition: var(--default-transition);
}
.all-demo-btn-section.show {
  transform: translateX(0);
}
.all-demo-btn-section.show .all-demo {
  width: 60px;
  min-width: 60px;
  transform: translateX(-100%);
}
.all-demo-btn-section .all-demo {
  @apply absolute left-0 flex items-center justify-center min-w-[150px] h-[54px] py-[12px] px-[15px] shadow-[0_0_15px_rgba(0,0,0,0.15)] font-medium uppercase cursor-pointer;
  z-index: 10;
  transform: translateX(calc(-100% - 15px));
  transition: var(--default-transition);
}
.all-demo-btn-section .unique-demos .scroll-content .grid-wrapper ul li a .portfolio-switchimg .portfolio-content span {
  text-transform: capitalize;
}

.smooth-scrollbar[data-scrollbar] {
  display: flex;
  align-items: center;
}
.smooth-scrollbar[data-scrollbar].scrollbar-appear {
  display: block;
}

@media (max-width: 1024px) {
  .scroll-top-arrow {
    display: none !important;
  }
}
.text-basecolor {
  color: var(--base-color);
}

.hover\:text-basecolor:hover {
  color: var(--base-color);
}

.bg-basecolor {
  background-color: var(--base-color);
}

.bg-transparent-gradient-smoky-black {
  background: radial-gradient(circle, rgba(16, 16, 16, 0) 0, #101010 100%);
}

.border-basecolor {
  border-color: var(--base-color);
}

.bg-gradient-dark-slate-blue {
  background-image: linear-gradient(to right, #252c3e, rgba(37, 44, 62, 0.66), rgba(37, 44, 62, 0.42), rgba(37, 44, 62, 0.1), rgba(37, 44, 62, 0));
}

.after\:bg-basecolor:after {
  background-color: var(--base-color);
}

.before\:bg-basecolor:before {
  background-color: var(--base-color);
}

.btn-box-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  letter-spacing: 1px;
  padding: 18px 38px;
}

.btn-box-shadow-flat {
  box-shadow: 2px 2px 0 #000;
  border-width: 1px;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-input,
.small-textarea {
  padding: 13px 15px;
  font-size: 14px;
}

.small-select select {
  padding: 13px 15px;
}

.medium-input,
.medium-textarea,
.medium-select select {
  padding: 15px 20px;
  font-size: 14px;
}

.extra-medium-textarea {
  font-size: 14px;
}

.large-input {
  padding: 18px 25px 19px;
}

.extra-large-input {
  padding: 20px 36px 23px;
  font-size: 16px;
}

/* Custom */
@keyframes video-icon-sonar {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.1);
    transform: translate(-50%, -50%) scale(0.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }
}
@keyframes down-arrow {
  0% {
    opacity: 0;
    top: 0px;
  }
  18% {
    opacity: 0.2;
    top: 3px;
  }
  36% {
    opacity: 0.4;
    top: 8px;
  }
  54% {
    opacity: 0.6;
    top: 18px;
  }
  72% {
    opacity: 0.8;
    top: 18px;
  }
  100% {
    opacity: 1;
    top: 18px;
  }
}
@keyframes zoomout {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* text stroke */
.text-border {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
}

.text-gradient-peacock-blue-crome-yellow {
  background: linear-gradient(to right, #36c1e1, #36c1e1, #74cf8e, #bac962, #feb95b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* text stroke width */
.text-border-width-1px {
  -webkit-text-stroke-width: 1px;
}

.text-border-width-2px {
  -webkit-text-stroke-width: 2px;
}

.text-border-width-3px {
  -webkit-text-stroke-width: 3px;
}

.text-border-width-4px {
  -webkit-text-stroke-width: 4px;
}

.text-border-width-5px {
  -webkit-text-stroke-width: 5px;
}

/* text stroke fill color */
.text-stroke-fill-transparent {
  -webkit-text-fill-color: transparent;
}

/* border gradient */
.border-gradient-light-purple-light-red {
  border-image: linear-gradient(to right top, #bb85f9, #c887e5, #da8bca, #eb8fb0, #fb9398);
  border-image-slice: 1;
}

/* for safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .border-gradient-light-purple-light-red {
    border-image: linear-gradient(to right top, #bb85f9, #c887e5, #da8bca, #eb8fb0, #fb9398) 1/0 0 1 0;
    border-image-slice: 1;
  }
}
/* Box shadow */
.box-shadow,
.box-shadow-hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.box-shadow-double-large,
.box-shadow-double-large-hover:hover {
  box-shadow: 0 0 35px rgba(40, 42, 54, 0.18);
}

.box-shadow-large,
.box-shadow-large-hover:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.box-shadow-extra-large,
.box-shadow-extra-large-hover:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
}

.box-shadow-medium,
.box-shadow-medium-hover:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.box-shadow-small,
.box-shadow-small-hover:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

/* text shadow */
.text-shadow-small {
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

.text-shadow-medium {
  text-shadow: rgba(0, 0, 0, 0.03) 0px 10px 10px;
}

.text-shadow-large {
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
}

.text-shadow-extra-large {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.9);
}

.text-shadow-double-large {
  text-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
}

/* text decoration underline */
.text-decoration-line-bottom,
.text-decoration-line-bottom-medium,
.text-decoration-line-bottom-thick,
.text-decoration-line-thick,
.text-decoration-line-wide,
.text-middle-line {
  position: relative;
  white-space: nowrap;
}

.text-decoration-line-wide:before {
  content: "";
  border-bottom: 1px solid;
  left: -10px;
  position: absolute;
  top: 50%;
  width: calc(100% + 20px);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-decoration-line-thick:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #333333;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: -1;
}

.text-decoration-line-bottom:before {
  content: "";
  border-bottom: 1px solid;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

.text-decoration-line-bottom-medium:before {
  content: "";
  border-bottom: 2px solid;
  left: 0;
  position: absolute;
  top: 90%;
  width: 100%;
}

.text-decoration-line-bottom-thick:before {
  content: "";
  border-bottom: 3px solid;
  left: 0;
  position: absolute;
  top: 85%;
  width: 100%;
}

/* Image mask */
.image-mask {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* Image back offset shadow */
.image-back-offset-shadow {
  z-index: 1;
  @apply opacity-100 m-0 relative inline-block;
  transition: var(--default-transition);
}
.image-back-offset-shadow.active {
  @apply opacity-100;
}
.image-back-offset-shadow span {
  background-image: linear-gradient(to right top, #b783ff, #e37be0, #fa7cc1, #ff85a6, #ff9393);
  @apply absolute top-0 left-0 w-full h-full -z-[1];
}

.transition-default {
  transition: var(--default-transition);
}

/* Parallax */
@screen xl {
  .xl-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen lg {
  .lg-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen md {
  .md-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen sm {
  .sm-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen xs {
  .xs-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen xxs {
  .xxs-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

/* Css Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(20px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}
.random-char-fadein span {
  display: inline-block;
  opacity: 0;
  filter: blur(20px);
  transform: translateY(100px);
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
}
.random-char-fadein span:nth-child(even).active {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}
.random-char-fadein span:nth-child(odd) {
  transition-delay: 0.5s;
}
.random-char-fadein span:nth-child(odd).active {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}

h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  margin: 0 0 25px 0;
  padding: 0;
  letter-spacing: -0.5px;
  font-weight: inherit;
}

h1, .heading-1 {
  font-size: 5rem;
  line-height: 5rem;
}

h2, .heading-2 {
  font-size: 3.9rem;
  line-height: 4.2rem;
}

h3, .heading-3 {
  font-size: 3.4rem;
  line-height: 3.9rem;
}

h4, .heading-4 {
  font-size: 2.85rem;
  line-height: 3.4rem;
}

h5, .heading-5 {
  font-size: 2.3rem;
  line-height: 3rem;
}

h6, .heading-6 {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

@screen lg {
  h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    margin: 0 0 15px 0;
  }
}
@screen md {
  .text-xl {
    font-size: 18px;
    line-height: 26px;
  }
}

@screen xl {
  .text-xbig {
    font-size: 200px;
    line-height: 200px;
  }
}
@screen lg {
  .text-xbig {
    font-size: 170px;
    line-height: 170px;
  }
}
@screen md {
  .text-xbig {
    font-size: 130px;
    line-height: 130px;
  }
}
@screen sm {
  .text-xbig {
    font-size: 100px;
    line-height: 100px;
  }
}
@screen xs {
  .text-xbig {
    font-size: 55px;
    line-height: 55px;
  }
}

/* Components */
/*client-logo-style-01*/
.client-logo-style-01 > .col {
  @apply relative;
}
.client-logo-style-01 .client-box {
  z-index: 0;
  border: 1px solid #939393;
  transition-duration: 0.3s;
  opacity: 1;
}
.client-logo-style-01 .client-box a {
  position: relative;
  z-index: 10;
}
.client-logo-style-01 .client-box:hover {
  z-index: 10;
}
.client-logo-style-01 .client-box:hover:before {
  transition: height 0.2s ease, opacity 0.3s ease;
  height: calc(100% - 20px);
  opacity: 0.7;
}
.client-logo-style-01 .client-box:hover:after {
  transition: width 0.2s ease, opacity 0.3s ease;
  width: calc(100% - 20px);
  opacity: 0.8;
}
.client-logo-style-01 .client-box:before, .client-logo-style-01 .client-box:after {
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  opacity: 1;
}
.client-logo-style-01 .client-box:before {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: 1;
  transition: height 1s ease, opacity 0.8s ease;
}
.client-logo-style-01 .client-box:after {
  height: calc(100% + 4px);
  width: 100%;
  z-index: 1;
  transition: width 1s ease, opacity 0.8s ease;
}
.client-logo-style-01 .client-box img {
  z-index: 2;
  margin: 0 auto;
}

/* client logo style 02 */
.client-logo-style-02 .client-box {
  @apply text-center;
}
.client-logo-style-02 a {
  opacity: 0.6;
  transition: var(--default-transition);
  display: inline-block;
}
.client-logo-style-02 a:hover {
  opacity: 1;
}

/* client logo style 03*/
.client-logo-style-03 {
  transition: var(--default-transition);
}
.client-logo-style-03 img {
  filter: grayscale(1);
  transition-property: filter;
  transition-duration: 0.4s;
}
.client-logo-style-03 a:hover img {
  filter: grayscale(0);
  transition-property: filter;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}
.client-logo-style-03 .swiper-button-prev, .client-logo-style-03 .swiper-button-next {
  @apply hidden;
}

/* client logo style 04*/
.client-logo-style-04 a {
  @apply inline-block relative p-[25px];
}
.client-logo-style-04 a:after {
  content: "";
  @apply inline-block absolute top-0 left-0 w-full h-full bg-white z-0 opacity-0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  transform: scale(0.8);
  transition: var(--default-transition);
}
.client-logo-style-04 a:hover:after {
  opacity: 1;
  transform: scale(1);
}
.client-logo-style-04 img {
  @apply relative z-[1];
}
.client-logo-style-04 .client-box {
  text-align: center;
  padding: 0;
}

/* client logo style 05*/
.client-logo-style-05 a {
  @apply block;
}
.client-logo-style-05 a:hover {
  outline: 0;
  outline-offset: 0;
}
.client-logo-style-05 a:hover img {
  transform: translateY(10px);
  -webkit-transform: translateY(-10px);
}
.client-logo-style-05 a img {
  transition: var(--default-transition);
}

/* client logo style 06*/
.client-logo-style-06 > div[class^=col] {
  @apply flex items-center justify-center border-solid border-b border-r xs:border-r-0;
  border-color: rgba(255, 255, 255, 0.1);
  padding: 4rem 15px;
  margin-bottom: 0;
}
.client-logo-style-06 > div[class^=col]:nth-child(4) {
  border-right: 0;
}
.client-logo-style-06 > div[class^=col]:nth-last-of-type(-n + 4) {
  @apply border-b-0 border-solid sm:border-b;
}
.client-logo-style-06 > div[class^=col]:nth-last-of-type(-n + 2) {
  @apply sm:border-b-0 xs:border-b;
}
.client-logo-style-06 > div[class^=col]:nth-child(even) {
  @apply sm:border-r-0;
}
.client-logo-style-06 > div[class^=col]:last-child {
  @apply border-r-0 xs:border-b-0;
}
.client-logo-style-06.swiper {
  border-right: 0;
}
.client-logo-style-06 .swiper-slide img {
  margin: 0 auto;
}
.client-logo-style-06 img {
  transition: var(--default-transition);
  opacity: 0.5;
  filter: brightness(0) invert(1);
}
.client-logo-style-06 img:hover {
  opacity: 1;
}
.client-logo-style-06.light > div[class^=col] {
  @apply flex items-center justify-center border-solid border-b border-r xs:border-r-0;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 4rem 0px;
  margin-bottom: 0;
}
.client-logo-style-06.light > div[class^=col]:nth-child(4) {
  border-right: 0;
}
.client-logo-style-06.light > div[class^=col]:nth-last-of-type(-n + 4) {
  @apply border-b-0 border-solid sm:border-b;
}
.client-logo-style-06.light > div[class^=col]:nth-last-of-type(-n + 2) {
  @apply sm:border-b-0 xs:border-b;
}
.client-logo-style-06.light > div[class^=col]:nth-child(even) {
  @apply sm:border-r-0;
}
.client-logo-style-06.light > div[class^=col]:last-child {
  @apply border-r-0 xs:border-b-0;
}
.client-logo-style-06.light img {
  filter: brightness(1) invert(0);
}

/* client logo style 07 */
.client-logo-style-07 > .col {
  filter: blur(0);
  transition: var(--default-transition);
}
.client-logo-style-07:hover .swiper-slide {
  transition: var(--default-transition);
  transition-duration: 0.5s;
  filter: blur(2px);
}
.client-logo-style-07:hover > .col {
  filter: blur(2px);
  opacity: 0.6;
}
.client-logo-style-07:hover > .col:hover {
  filter: blur(0);
  opacity: 1;
}
.client-logo-style-07 .swiper-slide:hover {
  filter: blur(0);
}
.client-logo-style-07 img {
  margin: 0 auto;
}

/* client logo style 08 */
.client-logo-style-08 .col {
  border-right: 1px solid rgba(0, 0, 0, 0.1019607843);
}
.client-logo-style-08 .col:last-child {
  border-right: 0;
}
@screen xs {
  .client-logo-style-08 .col {
    border-right: 0;
    border-bottom: 0;
  }
}
@screen sm {
  .client-logo-style-08 .col {
    @apply border-b last:border-b-0;
  }
  .client-logo-style-08 .col:nth-child(even) {
    @apply sm:border-r-0;
  }
}
@screen md {
  .client-logo-style-08 .col:last-child {
    @apply border-r-0;
  }
}
.client-logo-style-08 .col .client-box {
  @apply flex items-center justify-center py-[50px] xs:py-[40px] text-center sm:border-b;
}

.client-box img {
  margin: 0 auto;
}

.client-slider-wrapper .swiper-button-next {
  right: -60px;
  @apply right-[-79px] lg:right-[-35px] landscape:md:right-[-10px];
}
.client-slider-wrapper .swiper-button-prev {
  @apply left-[-93px] lg:left-[-35px] landscape:md:left-[-10px];
}

.grid {
  list-style: none;
  /* gutter size */
  /* grid 6 column */
  /* grid 5 column */
  /* grid 4 column */
  /* grid 3 column */
  /* grid 2 column */
  /* grid 1 column */
}
.grid.grid-container {
  display: inherit;
  transition: var(--default-transition);
}
.grid li > .appear {
  opacity: 1 !important;
}
.grid li > .appear > [class*=portfolio-] {
  opacity: 1 !important;
}
.grid.gutter-very-small li {
  padding: 3px 3px;
}
.grid.gutter-small li {
  padding: 5px 5px;
}
.grid.gutter-medium li {
  padding: 7px 7px;
}
.grid.gutter-large li {
  padding: 10px 10px;
}
.grid.gutter-extra-large li {
  padding: 15px;
}
.grid.gutter-double-extra-large li {
  padding: 20px;
}
.grid.gutter-triple-extra-large li {
  padding: 30px;
}
.grid.grid-6col li {
  width: 16.67%;
  list-style: none;
}
.grid.grid-6col li.grid-item-double {
  width: 33.33%;
}
.grid.grid-5col li {
  width: 20%;
  list-style: none;
}
.grid.grid-5col li.grid-item-double {
  width: 40%;
}
.grid.grid-4col li {
  width: 25%;
  list-style: none;
}
.grid.grid-4col li.grid-item-double {
  width: 50%;
}
.grid.grid-3col li {
  width: 33.33%;
  list-style: none;
}
.grid.grid-3col li.grid-item-double {
  width: 66.67%;
}
.grid.grid-2col li {
  width: 50%;
  list-style: none;
}
.grid.grid-2col li.grid-item-double {
  width: 100%;
}
.grid.grid-1col li {
  width: 100%;
  list-style: none;
}
.grid .grid-sizer {
  padding: 0 !important;
  margin: 0 !important;
}

@screen xl {
  .grid.xl-grid-6col li {
    width: 16.67%;
  }
  .grid.xl-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xl-grid-5col li {
    width: 20%;
  }
  .grid.xl-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xl-grid-4col li {
    width: 25%;
  }
  .grid.xl-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xl-grid-3col li {
    width: 33.33%;
  }
  .grid.xl-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xl-grid-2col li {
    width: 50%;
  }
  .grid.xl-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xl-grid-1col li {
    width: 100%;
  }
}
@screen lg {
  .grid.lg-grid-6col li {
    width: 16.67%;
  }
  .grid.lg-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.lg-grid-5col li {
    width: 20%;
  }
  .grid.lg-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.lg-grid-4col li {
    width: 25%;
  }
  .grid.lg-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.lg-grid-3col li {
    width: 33.33%;
  }
  .grid.lg-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.lg-grid-2col li {
    width: 50%;
  }
  .grid.lg-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.lg-grid-1col li {
    width: 100%;
  }
}
@screen md {
  .grid.md-grid-6col li {
    width: 16.67%;
  }
  .grid.md-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.md-grid-5col li {
    width: 20%;
  }
  .grid.md-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.md-grid-4col li {
    width: 25%;
  }
  .grid.md-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.md-grid-3col li {
    width: 33.33%;
  }
  .grid.md-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.md-grid-2col li {
    width: 50%;
  }
  .grid.md-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.md-grid-1col li {
    width: 100%;
  }
}
@screen sm {
  .grid.sm-grid-6col li {
    width: 16.67%;
  }
  .grid.sm-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.sm-grid-5col li {
    width: 20%;
  }
  .grid.sm-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.sm-grid-4col li {
    width: 25%;
  }
  .grid.sm-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.sm-grid-3col li {
    width: 33.33%;
  }
  .grid.sm-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.sm-grid-2col li {
    width: 50%;
  }
  .grid.sm-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.sm-grid-1col li {
    width: 100%;
  }
  .grid.gutter-small li {
    padding: 7px 7px;
  }
  .grid.gutter-double-extra-large li {
    padding: 15px;
  }
  .grid.gutter-medium li {
    padding: 7px 7px;
  }
  .grid.gutter-large li {
    padding: 10px 10px;
  }
}
@screen xs {
  .grid.xs-grid-6col li {
    width: 16.67%;
  }
  .grid.xs-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xs-grid-5col li {
    width: 20%;
  }
  .grid.xs-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xs-grid-4col li {
    width: 25%;
  }
  .grid.xs-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xs-grid-3col li {
    width: 33.33%;
  }
  .grid.xs-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xs-grid-2col li {
    width: 50%;
  }
  .grid.xs-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xs-grid-1col li {
    width: 100%;
  }
  .grid.xs-grid-6col li {
    width: 16.67%;
  }
  .grid.xs-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xs-grid-5col li {
    width: 20%;
  }
  .grid.xs-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xs-grid-4col li {
    width: 25%;
  }
  .grid.xs-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xs-grid-3col li {
    width: 33.33%;
  }
  .grid.xs-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xs-grid-2col li {
    width: 50%;
  }
  .grid.xs-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xs-grid-1col li {
    width: 100%;
  }
  .grid.gutter-medium li, .grid.gutter-large li {
    padding: 7px 15px;
  }
}
/* Loading */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.grid.loading {
  @apply relative z-[1];
  transition: var(--default-transition);
}
.grid.loading::after {
  @apply bg-none absolute top-0 w-[40px] h-[40px] opacity-100 right-[inherit] z-10 text-center;
  content: "";
  left: calc(50% - 10px);
  border: 2px solid rgba(23, 23, 23, 0.2);
  border-top-color: rgba(23, 23, 23, 0.7);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  transition: var(--default-transition);
}
.grid.loading.dark:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.grid.loading.dark:after {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.7);
}

/* interactivebanners-style-01 */
.interactivebanners-style-01 .interactive-banners-image {
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  opacity: 0;
  top: 0;
}
.interactivebanners-style-01 h3 {
  @apply text-darkgray;
}
.interactivebanners-style-01:hover span {
  color: #fff;
}
.interactivebanners-style-01:hover h3 {
  color: #fff;
}
.interactivebanners-style-01:hover a i {
  color: #fff;
}
.interactivebanners-style-01:hover .interactive-banners-image {
  opacity: 1;
  transform: scale(1.1, 1.1);
}

/* interactivebanners-style-02 */
.interactivebanners-style-02 .swiper-wrapper {
  cursor: url("../img/icon-move-light.png"), move;
}
.interactivebanners-style-02 .interactivebanners-main {
  position: relative;
  transition: 0.3s ease-in-out;
}
.interactivebanners-style-02 .interactivebanners-main .interactivebanners-subtitle {
  @apply rounded-[2px] inline-block;
  background-color: var(--base-color, #0038e3);
  transition: all 0.5s ease-in-out;
}
.interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
  width: 80%;
  transition: all 0.5s ease-in-out;
}
@screen xl {
  .interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
    width: 90%;
  }
}
@screen lg {
  .interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
    width: 80%;
  }
}
@screen md {
  .interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
    width: 90%;
  }
}
@screen sm {
  .interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
    width: 60%;
  }
}
@screen xs {
  .interactivebanners-style-02 .interactivebanners-main .interactivebanners-title {
    width: 100%;
  }
}
.interactivebanners-style-02 .interactivebanners-main .btn {
  opacity: 0;
  transform: translateY(15px);
  transition: all 0.5s ease-in-out;
}
.interactivebanners-style-02 .interactivebanners-main .fancy-text-content {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  height: auto;
  transform: translateY(10px);
  transition: all 0.5s ease-in-out;
}
.interactivebanners-style-02 .interactivebanners-main img {
  transition: opacity 0.5s ease-in-out;
  width: 100%;
}
.interactivebanners-style-02 .interactivebanners-main:hover {
  background: #1f232c;
}
.interactivebanners-style-02 .interactivebanners-main:hover .fancy-text-content {
  transform: translateY(-50px);
}
.interactivebanners-style-02 .interactivebanners-main:hover .interactivebanners-subtitle {
  opacity: 0;
}
.interactivebanners-style-02 .interactivebanners-main:hover .interactivebanners-image {
  opacity: 0.7;
}
.interactivebanners-style-02 .interactivebanners-main:hover .btn {
  opacity: 1;
  transform: translateY(0);
}
.interactivebanners-style-02 .interactivebanners-main:hover img {
  opacity: 0.7;
}
.interactivebanners-style-02 .overlay-bg::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #1f232c 100%);
}

/* interactivebanners-style-03 */
.interactivebanners-style-03 {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.interactivebanners-style-03 .interactivebanners-img {
  transition: var(--default-transition);
}
.interactivebanners-style-03 .interactivebanners-btn {
  opacity: 0;
  @apply bg-white border-white border-2;
  transform: translate(-50%, -50%);
  transition: var(--default-transition);
}
.interactivebanners-style-03 .interactivebanners-btn i {
  transition: var(--default-transition);
}
.interactivebanners-style-03 .interactivebanners-box-hover {
  opacity: 0;
  background: var(--overlay-color, #000);
  transition: var(--default-transition);
}
.interactivebanners-style-03 .interactiveanners-subtitle {
  transition: var(--default-transition);
  transition-duration: 0.5s;
  transition: 0.3s ease-in-out;
}
.interactivebanners-style-03 .interactivebanners-box-content a {
  @apply text-darkgray;
}
.interactivebanners-style-03 .interactivebanners-box-content .content-hover-effect .interactivebanners-box-sub-title {
  color: #2ed47c;
}
.interactivebanners-style-03 .interactivebanners-box-sub-title {
  opacity: 0;
  transform: translateY(15px);
  transition: var(--default-transition);
}
.interactivebanners-style-03 .interactivebanners-box-sub-title .interactivebanners-subtitle {
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.interactivebanners-style-03 .interactivebanners-box-sub-title a {
  color: #2ed47c;
}
.interactivebanners-style-03:hover .interactivebanners-img {
  transform: scale(1.1);
  transition-duration: 0.3s;
}
.interactivebanners-style-03:hover .interactivebanners-btn:hover {
  background: transparent;
}
.interactivebanners-style-03:hover .interactivebanners-btn:hover i {
  @apply text-white;
}
.interactivebanners-style-03:hover .interactivebanners-btn,
.interactivebanners-style-03:hover .interactivebanners-box-hover {
  opacity: 1;
}
.interactivebanners-style-03:hover .interactivebanners-subtitle {
  opacity: 0;
}
.interactivebanners-style-03:hover .interactivebanners-box-sub-title {
  opacity: 1;
  transform: translateY(0);
}

/* interactivebanners-style-04 */
.interactive-banners-style-04 figure {
  @apply relative overflow-hidden;
}
.interactive-banners-style-04 figure img {
  transition-duration: 2s;
}
.interactive-banners-style-04 figure:hover img {
  transform: scale(1.4);
  transition-duration: 4s;
}
.interactive-banners-style-04 figure:hover .interactive-banners-overlay {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-04 figcaption {
  @apply absolute top-0 left-0 w-full h-full;
}
.interactive-banners-style-04 figcaption:hover .interactive-banners-content .interactive-banners-hover-icon {
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-04 figcaption:hover .interactive-banners-hover-action {
  opacity: 1;
  bottom: 0;
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-04 .interactive-banners-content .interactive-banners-hover-icon {
  @apply absolute left-[60px] bottom-[60px];
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-04 .interactive-banners-hover-action {
  @apply absolute -bottom-[25px] h-full w-full;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-04 .interactive-banners-action-content {
  @apply overflow-hidden min-h-[75px];
  text-overflow: ellipsis;
}
.interactive-banners-style-04 .interactive-banners-overlay {
  opacity: 0;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: var(--overlay-color, #000);
  transition: all 0.5s ease-in-out;
}

/* interactivebanners-style-05 */
.interactive-banners-style-05 .figure .interactive-banners-image img {
  transition: var(--default-transition);
}
.interactive-banners-style-05 .figure:hover img {
  opacity: 0.4;
}
.interactive-banners-style-05 .figure:hover .interactive-banners-details a {
  transform: scale(1, 1);
  opacity: 1;
}
.interactive-banners-style-05 .figure:hover figcaption {
  transform: translateY(30px);
  opacity: 0;
}
.interactive-banners-style-05 .figure .interactive-banners-details a {
  transform: scale(0.9, 0.9);
  opacity: 0;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  transition: var(--default-transition);
}
.interactive-banners-style-05 .figure figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(50%);
  transition-duration: 0.3s;
}
.interactive-banners-style-05 .figure figcaption > div {
  transition: all 0.3s ease-in-out;
}

/* interactivebanners-style-06*/
.interactive-banners-style-06 {
  transition-duration: 0.4s;
  overflow: hidden;
}
.interactive-banners-style-06 .interactivebanners-image {
  transition: var(--default-transition);
}
.interactive-banners-style-06 .interactivebanners-image a {
  transition: var(--default-transition);
}
.interactive-banners-style-06 .interactivebanners-image a img {
  transition-duration: 0.4s;
}
.interactive-banners-style-06:hover .interactivebanners-image {
  left: -20%;
  filter: grayscale(100%);
  opacity: 0.8;
}
.interactive-banners-style-06:hover .interactivebanners-content h2 {
  margin-bottom: 10px;
}
.interactive-banners-style-06:hover .interactivebanners-content a {
  opacity: 1;
  transform: scale(1, 1);
  border-bottom: 2px solid;
}
.interactive-banners-style-06 .interactivebanners-content h2 {
  transition: var(--default-transition);
}
.interactive-banners-style-06 .interactivebanners-content a {
  opacity: 0;
  transition: var(--default-transition);
  align-self: flex-start;
  color: var(--base-color, #0038e3);
  border-radius: 0;
}

/* interactivebanners-style-07*/
.interactive-banners-style-07 {
  @apply inline-block border-0 relative;
  transition: var(--default-transition);
}
.interactive-banners-style-07:hover .interactive-banners-hover {
  opacity: 1;
}
.interactive-banners-style-07:hover .interactive-banners-image img {
  transform: scale3d(1.1, 1.1, 1);
  opacity: 0.5;
}
.interactive-banners-style-07:hover .interactive-banners-icon {
  opacity: 1;
  transform: translateX(-18px);
}
.interactive-banners-style-07:hover .interactive-banners-title {
  opacity: 0;
  transform: translateY(70px);
}
.interactive-banners-style-07:hover .interactive-banners-sub-title {
  opacity: 1;
  transform: translateY(10px);
}
.interactive-banners-style-07 .interactive-banners-image {
  @apply relative overflow-hidden  rounded-[6px] bg-darkslateblue;
}
.interactive-banners-style-07 .interactive-banners-image img {
  @apply w-full;
  transition: var(--default-transition);
  transform: scale3d(1, 1, 1);
}
.interactive-banners-style-07 .interactive-banners-hover {
  @apply absolute w-full h-full left-0 top-0;
  transition: var(--default-transition);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, #232323 100%);
}
.interactive-banners-style-07 .interactive-banners-icon {
  @apply w-[35px] h-[35px] leading-[36px] rounded-full text-center absolute top-1/2 left-1/2 -mt-[18px] -translate-x-1/2 -translate-y-1/2;
  opacity: 0;
  transition: var(--default-transition);
}
.interactive-banners-style-07 .interactive-banners-title {
  opacity: 1;
  transform: translateY(35px);
  transition: var(--default-transition);
}
.interactive-banners-style-07 .interactive-banners-sub-title {
  opacity: 0;
  transform: translateY(70px);
  text-align: center;
  transition: var(--default-transition);
}

/* interactivebanners-style-08*/
.interactive-banners-style-08 .interactive-banners-image a .category-name {
  transition: var(--default-transition);
  @apply bg-white text-darkgray;
}
.interactive-banners-style-08 .interactive-banners-image .overlay-banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
}
.interactive-banners-style-08 .interactive-banners-image .category-content .interactive-banners-icon {
  opacity: 0;
  transform: translateY(10px);
  transition: var(--default-transition);
}
.interactive-banners-style-08:hover .interactive-banners-image a .category-name {
  @apply bg-darkgray text-white;
}
.interactive-banners-style-08:hover .interactive-banners-image .category-content .interactive-banners-icon {
  opacity: 1;
  transform: translateY(0);
}

/* interactivebanners-style-09*/
.interactive-banners-style-09:hover .interactive-banners-content .tour-button {
  bottom: 0;
  opacity: 1;
}
.interactive-banners-style-09 .interactive-banners-content .tour-button {
  @apply absolute -bottom-[30px] my-0 mx-auto left-0 right-0;
  transition: var(--default-transition);
  opacity: 0;
}

.interactive-banners-style-10 {
  @apply text-center;
}
.interactive-banners-style-10 .interactive-banners-box {
  @apply relative overflow-hidden;
}
.interactive-banners-style-10 .interactive-banners-box:hover .interactive-banners-box-image img {
  filter: blur(3px);
  transform: scale(1.1);
}
.interactive-banners-style-10 .interactive-banners-box:hover .interactive-banners-box-image .interactive-banners-text-overlay {
  opacity: 0.77;
}
.interactive-banners-style-10 .interactive-banners-box:hover .interactive-banners-text-content {
  transform: translateY(-65px);
}
.interactive-banners-style-10 .interactive-banners-box:hover .interactive-banners-text-content button {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-box-image {
  @apply relative overflow-hidden;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-box-image img {
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-box-image .interactive-banners-text-overlay {
  @apply absolute top-0 left-0 w-full h-full bg-[#262b35];
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-box-image .overlay {
  @apply absolute h-full w-full top-0 left-0;
  opacity: 0.5;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #1f232c 100%);
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-text-content {
  @apply absolute w-full left-0 top-auto bottom-0 h-auto px-20 sm:px-12;
  z-index: 9;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-text-content h6 {
  @apply mb-[5px] font-semibold  font-serif uppercase;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-text-content span {
  @apply tracking-[1px]  text-md font-serif uppercase block;
  opacity: 0.7;
}
.interactive-banners-style-10 .interactive-banners-box .interactive-banners-text-content button {
  @apply text-xxs tracking-[.5px] py-[10px] px-[24px] mt-[35px];
  opacity: 0;
  transform: translateY(15px);
}

.interactive-banners-style-11 figure figcaption {
  @apply absolute top-0 left-0 h-full w-full;
  opacity: 0;
  transition: var(--default-transition);
}
.interactive-banners-style-11 figure figcaption a {
  transition: var(--default-transition);
  transform: translateY(-15px);
}
.interactive-banners-style-11 figure:hover figcaption {
  opacity: 1;
}
.interactive-banners-style-11 figure:hover figcaption a {
  transform: translateY(0);
}

.interactive-banners-style-12 {
  @apply relative overflow-hidden;
}
.interactive-banners-style-12:hover .interactive-banners-image .section-link {
  opacity: 0;
  transform: translateY(100%);
}
.interactive-banners-style-12:hover .interactive-banners-content {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.interactive-banners-style-12:hover .interactive-banners-content .overlayer-box {
  @apply h-full;
}
.interactive-banners-style-12:hover .interactive-banners-content .interactive-banners-content-text {
  @apply visible;
  opacity: 1;
}
.interactive-banners-style-12 .interactive-banners-image {
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
}
.interactive-banners-style-12 .interactive-banners-image .overlay-bg {
  @apply absolute h-full w-full top-0 left-0;
  opacity: 0.75;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #141414 150%);
}
.interactive-banners-style-12 .interactive-banners-image a {
  @apply rounded-[50%] h-[35px] w-[35px] right-[45px] bottom-[50px] border-[#fff] border-[2px] z-[1] leading-[32px] text-[#fff] text-center absolute;
  transition: var(--default-transition);
}
.interactive-banners-style-12 .interactive-banners-content {
  @apply absolute bottom-0 left-0 w-full z-[1] p-14;
  transform: translateY(calc(100% - 130px));
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
}
.interactive-banners-style-12 .interactive-banners-content .overlayer-box {
  @apply h-0 -z-[1] bottom-0 top-auto absolute w-full left-0 bg-darkgray;
  transition: all 0.4s cubic-bezier(0.2, 0, 0.3, 1);
  opacity: 0.9;
  transition: var(--default-transition);
}
.interactive-banners-style-12 .interactive-banners-content .interactive-banners-content-text {
  opacity: 0;
  visibility: hidden;
  transition: var(--default-transition);
}

.interactive-banners-style-13 {
  display: inline-block;
  position: relative;
}
.interactive-banners-style-13 a:hover {
  color: #cb933d;
}
.interactive-banners-style-13 a:hover .interactive-banners-text {
  transform: translateX(0);
}
.interactive-banners-style-13 a:hover .interactive-banners-icon {
  transform: scaleX(1) translateX(0);
}
.interactive-banners-style-13 a .interactive-banners-text {
  transform: translateX(20%);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transform-origin: left center;
}
.interactive-banners-style-13 a .interactive-banners-icon {
  display: inline-block;
  transform: scaleX(0) translateX(0);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transform-origin: left center;
}

.interactive-banners-style-14 {
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure {
  @apply relative mb-0 overflow-hidden;
}
.interactive-banners-style-14 figure:hover figcaption .hover-content {
  @apply -translate-y-[40px];
}
.interactive-banners-style-14 figure:hover figcaption .hover-content .hover-show-content {
  opacity: 1;
  visibility: visible;
}
.interactive-banners-style-14 figure:hover figcaption .hover-content .hover-show-content .hover-content-detail {
  @apply h-auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure:hover figcaption .hover-action-btn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
}
.interactive-banners-style-14 figure:hover figcaption .overlayer-box {
  opacity: 0.85;
}
.interactive-banners-style-14 figure figcaption {
  @apply absolute top-0 left-0 w-full h-full z-[1];
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure figcaption .hover-content {
  @apply h-full text-center items-center flex-col flex;
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure figcaption .hover-content div {
  @apply opacity-60 text-white text-md leading-[20px] mt-auto font-serif;
}
.interactive-banners-style-14 figure figcaption .hover-content h6 {
  @apply font-medium text-white mb-0 font-serif w-1/2;
}
.interactive-banners-style-14 figure figcaption .hover-content .hover-show-content {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure figcaption .hover-content .hover-show-content p {
  @apply min-h-[50px] h-[50px] overflow-hidden w-[60%] leading-[26px] mx-auto text-[#ffffff80];
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: translate3d(0, 25px, 0);
}
.interactive-banners-style-14 figure figcaption .hover-action-btn {
  @apply absolute bottom-[40px] left-0 w-full text-center;
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure figcaption .hover-action-btn span {
  @apply h-[40px] w-[40px] bg-[#c89965] leading-[44px] rounded-[50%] inline-block;
}
.interactive-banners-style-14 figure figcaption .hover-action-btn span i {
  @apply text-lg text-[#333045];
}
.interactive-banners-style-14 figure figcaption .opacity-medium {
  @apply -z-[1] absolute h-full w-full opacity-75 top-0 left-0 bg-[#333045];
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-14 figure figcaption .overlayer-box {
  @apply absolute h-full w-full top-0 left-0 bg-[#333045] -z-[1];
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

/* interactivebanners-style-15 */
.interactive-banners-style-15 figure {
  @apply relative overflow-hidden;
}
.interactive-banners-style-15 figure img {
  transition-duration: 2s;
}
.interactive-banners-style-15 figure:hover img {
  transform: scale(1.4);
  transition-duration: 4s;
}
.interactive-banners-style-15 figcaption {
  @apply absolute top-0 left-0 w-full h-full;
}
.interactive-banners-style-15 figcaption:hover .interactive-banners-hover-action {
  opacity: 1;
  bottom: 0;
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-15 figcaption:hover .interactive-banners-content .interactive-banners-hover-icon {
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-15 .interactive-banners-content .interactive-banners-hover-icon {
  @apply absolute left-[60px] bottom-[60px];
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-15 .interactive-banners-hover-action {
  opacity: 0;
  @apply absolute -bottom-[25px] h-full w-full;
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-15 .bg-transparent-gradiant-white-black {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.18) 18%, rgba(0, 0, 0, 0.95) 100%);
}


.interactive-banners-style-16 {
  @apply text-center;
}
.interactive-banners-style-16 .interactive-banners-box {
  @apply relative overflow-hidden;
}
.interactive-banners-style-16 .interactive-banners-box:hover .interactive-banners-box-image img {
  filter: blur(3px);
  transform: scale(1.1);
}
.interactive-banners-style-16 .interactive-banners-box:hover .interactive-banners-box-image .interactive-banners-text-overlay {
  opacity: 0.77;
}
/* .interactive-banners-style-16 .interactive-banners-box:hover .interactive-banners-text-content {
  transform: translateY(-65px);
}
.interactive-banners-style-16 .interactive-banners-box:hover .interactive-banners-text-content button {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
} */
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-box-image {
  @apply relative overflow-hidden;
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-box-image img {
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-box-image .interactive-banners-text-overlay {
  @apply absolute top-0 left-0 w-full h-full bg-[#262b35];
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-box-image .overlay {
  @apply absolute h-full w-full top-0 left-0;
  opacity: 0.5;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #1f232c 100%);
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-text-content {
  @apply absolute w-full left-0 top-auto bottom-0 h-[50vh] px-20 sm:px-12;
  z-index: 9;
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-text-content h6 {
  @apply mb-[5px] font-semibold  font-serif uppercase;
}
.interactive-banners-style-16 .interactive-banners-box .interactive-banners-text-content span {
  @apply tracking-[1px]  text-md font-serif uppercase block;
  opacity: 0.7;
}
/* .interactive-banners-style-16 .interactive-banners-box .interactive-banners-text-content button {
  @apply text-xxs tracking-[.5px] py-[10px] px-[24px] mt-[35px];
  opacity: 0;
  transform: translateY(15px);
} */

.interactive-banners-style-16 .interactive-banners-box .interactive-banners-text-content button {
  @apply text-lg mt-10 ;

}


.filter-menu {
  @apply sm:flex sm:flex-col;
}
.filter-menu .active {
  @apply text-black border-transparent;
}
.filter-menu .active span {
  @apply border-black border-b border-solid;
}
.filter-menu li {
  cursor: pointer;
  @apply sm:mb-[10px];
}
.filter-menu li:first-child {
  padding-left: 0;
}
.filter-menu li:last-child {
  padding-right: 0;
}
@screen sm {
  .filter-menu li:first-child {
    padding-left: 20px;
  }
  .filter-menu li:last-child {
    padding-right: 20px;
  }
}
.filter-menu li span {
  @apply border-b border-solid border-transparent;
  transition: var(--default-transition);
  @apply font-serif font-medium;
}
.filter-menu li span:hover {
  @apply text-black border-black;
}

.portfolio-classic {
  @apply p-0 rounded-[6px] overflow-hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-classic:hover {
  transform: translateY(-5px);
}
.portfolio-classic:hover .portfolio-image img {
  transform: scale(1, 1);
  opacity: 0.15;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon {
  opacity: 1;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a:hover,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}
.portfolio-classic .portfolio-image {
  @apply relative overflow-hidden;
  transition: var(--default-transition);
  background: var(--overlay-color, #000);
}
.portfolio-classic .portfolio-image img {
  @apply w-full;
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon {
  @apply inline-block absolute align-middle top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center opacity-0;
}
.portfolio-classic .portfolio-image .portfolio-icon a,
.portfolio-classic .portfolio-image .portfolio-icon > div {
  @apply w-[40px] h-[40px] inline-block align-middle leading-[40px] text-center my-0 mx-[5px] relative z-[3] top-1/2 bg-[#fff] text-[#262b35] rounded-[50%] cursor-pointer;
  opacity: 0;
  transform: translateY(15px);
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon a:last-child,
.portfolio-classic .portfolio-image .portfolio-icon > div:last-child {
  transition-delay: 0.2s;
}
.portfolio-classic .portfolio-image .portfolio-icon a i,
.portfolio-classic .portfolio-image .portfolio-icon > div i {
  @apply text-sm;
}
.portfolio-classic .portfolio-caption {
  @apply py-[30px] bg-[#fff] text-center lg:py-[20px];
}
.portfolio-classic .portfolio-caption a span {
  @apply font-medium text-darkgray font-serif;
}

.portfolio-box-boxed {
  @apply relative overflow-hidden;
}
.portfolio-box-boxed img {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-box-boxed:hover img {
  transform: scale(1.1);
}
.portfolio-box-boxed .portfolio-hover {
  @apply absolute left-0 right-0 text-center font-serif z-[999] my-0 mx-auto w-[90%] bottom-0 bg-[#fff] text-[#000] p-[15px] rounded-[3px];
}
.portfolio-box-boxed:hover .portfolio-hover {
  @apply my-[15px] mx-auto;
  transform: translateY(0);
}

.portfolio-boxed .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover {
  @apply left-[20px] bottom-[20px] h-auto;
  opacity: 0;
  width: calc(100% - 40px);
  top: inherit;
  transform: translateY(100%);
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover i {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover:hover i {
  transform: translateX(5px);
}
.portfolio-boxed:hover .portfolio-image img {
  transform: scale(1.1);
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-boxed:hover .porfolio-hover {
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.portfolio-colorful .portfolio-box {
  @apply relative;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover {
  @apply opacity-100;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover i {
  @apply opacity-100 translate-x-0;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content span,
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content h6 {
  transform: translateY(0);
}
.portfolio-colorful .portfolio-box img {
  @apply inline-block w-full h-auto;
}
.portfolio-colorful .portfolio-box .portfolio-hover {
  @apply absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
  background: var(--brand-color);
}
.portfolio-colorful .portfolio-box .portfolio-hover i {
  @apply absolute opacity-0 top-12 right-16 text-[28px] text-white;
  transition: var(--default-transition);
  transform: translateX(15px);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content {
  @apply absolute overflow-hidden text-left bottom-12 left-16;
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content span {
  transform: translateY(-100px);
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content h6 {
  @apply font-semibold text-white font-serif text-[1.8rem] leading-[2.5rem];
  transform: translateY(100px);
  transition: var(--default-transition);
  transition-duration: 0.5s;
}

.portfolio-bordered {
  @apply overflow-hidden;
}
.portfolio-bordered .portfolio-image {
  @apply relative;
  background: var(--overlay-color);
}
.portfolio-bordered .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover {
  @apply flex justify-center items-center bg-white absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover .scale {
  opacity: 0;
  transform: scale3d(1, 1, 1);
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image:hover img {
  opacity: 0.5;
  filter: blur(5px);
  transform: scale(1.1, 1.1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover {
  @apply left-[20px] top-[20px];
  opacity: 1;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  transform: scale3d(1, 1, 1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover .scale {
  opacity: 1;
}

.portfolio-overlay .portfolio-image {
  background: var(--overlay-color, #000);
}
.portfolio-overlay .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover {
  @apply top-0 left-0 w-full h-full opacity-0;
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover i {
  @apply absolute top-2/4 left-2/4;
  transform: translate(-50%, calc(-50% - 15px));
  transition: var(--default-transition);
}
.portfolio-overlay:hover .portfolio-image img {
  transform: scale(1.1);
  opacity: 0.15;
}
.portfolio-overlay:hover .portfolio-hover {
  opacity: 1;
}
.portfolio-overlay:hover .portfolio-hover i {
  transform: translate(-50%, -50%);
}

.portfolio-switchimg {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-switchimg .portfolio-image {
  @apply overflow-hidden relative rounded-[4px];
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image .portfolio-switch-image {
  @apply absolute top-0 left-0 w-full h-full;
  transition: var(--default-transition);
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1.1) translate3d(0, 0, 0);
}
.portfolio-switchimg .portfolio-content {
  transition: var(--default-transition);
}
.portfolio-switchimg:hover {
  transform: translateY(-5px);
}
.portfolio-switchimg:hover .portfolio-switch-image {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.portfolio-switchimg:hover .portfolio-content {
  transform: translateY(-10px);
}

@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+1) .portfolio-box {
    padding: 15% 0;
  }
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+3) .portfolio-box {
    padding: 0 15%;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
  padding: 25% 0;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
    padding: 15% 0;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
  padding: 0 15%;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
    padding: 0 15% 15%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(odd) .portfolio-box {
    padding: 0 0 10%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(even) .portfolio-box {
    padding: 0 10% 10%;
  }
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+1) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+3) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+5) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+4) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+6) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+1) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+3) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered > .col:nth-child(8n+1) .portfolio-box {
  padding: 25% 0;
}
.portfolio-scattered > .col:nth-child(8n+2) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered .portfolio-box .portfolio-image {
  clip-path: inset(0 0 0 0);
  transition: var(--default-transition);
}
.portfolio-scattered .portfolio-box:hover .portfolio-image {
  clip-path: inset(5% 5% 5% 5%);
}
.portfolio-scattered .portfolio-hover {
  transition: var(--default-transition);
}

.portfolio-swiper-slider {
  padding-bottom: 60px;
}
.portfolio-swiper-slider .swiper-button-prev,
.portfolio-swiper-slider .swiper-button-next {
  --swiper-navigation-background: transparent;
  transition: var(--default-transition);
  @apply text-darkgray text-xmd leading-[40px] h-[40px] w-[40px] mr-[5px] rounded-full border top-auto bottom-[10px] -translate-x-1/2;
}
.portfolio-swiper-slider .swiper-button-prev:hover,
.portfolio-swiper-slider .swiper-button-next:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: #fff;
  background: #fff;
}
.portfolio-swiper-slider .swiper-button-prev {
  left: calc(50% - 30px);
}
.portfolio-swiper-slider .swiper-button-next {
  left: calc(50% + 30px);
}

.portfolio-slider {
  transition: var(--default-transition);
  cursor: pointer;
}
.portfolio-slider .portfolio-image {
  transition: var(--default-transition);
}
.portfolio-slider .portfolio-hover {
  transition: var(--default-transition);
  transform: translateY(15px);
  opacity: 0;
}
.portfolio-slider:hover {
  transform: translateY(-5px);
}
.portfolio-slider:hover .portfolio-image {
  background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
  transform: scale(1, 1);
}
.portfolio-slider:hover .portfolio-image img {
  opacity: 0.15;
}
.portfolio-slider:hover .portfolio-hover {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translateY(0);
}

.portfolio-justified {
  overflow: hidden;
  position: static;
}
.portfolio-justified .portfolio-caption {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.portfolio-justified:hover .portfolio-caption {
  opacity: 1;
  visibility: visible;
}

.filter-menu {
  @apply sm:flex sm:flex-col;
}
.filter-menu .active {
  @apply text-black border-transparent;
}
.filter-menu .active span {
  @apply border-black border-b border-solid;
}
.filter-menu li {
  cursor: pointer;
  @apply sm:mb-[10px];
}
.filter-menu li:first-child {
  padding-left: 0;
}
.filter-menu li:last-child {
  padding-right: 0;
}
@screen sm {
  .filter-menu li:first-child {
    padding-left: 20px;
  }
  .filter-menu li:last-child {
    padding-right: 20px;
  }
}
.filter-menu li span {
  @apply border-b border-solid border-transparent;
  transition: var(--default-transition);
  @apply font-serif font-medium;
}
.filter-menu li span:hover {
  @apply text-black border-black;
}

.portfolio-classic {
  @apply p-0 rounded-[6px] overflow-hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-classic:hover {
  transform: translateY(-5px);
}
.portfolio-classic:hover .portfolio-image img {
  transform: scale(1, 1);
  opacity: 0.15;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon {
  opacity: 1;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a:hover,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}
.portfolio-classic .portfolio-image {
  @apply relative overflow-hidden;
  transition: var(--default-transition);
  background: var(--overlay-color, #000);
}
.portfolio-classic .portfolio-image img {
  @apply w-full;
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon {
  @apply inline-block absolute align-middle top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center opacity-0;
}
.portfolio-classic .portfolio-image .portfolio-icon a,
.portfolio-classic .portfolio-image .portfolio-icon > div {
  @apply w-[40px] h-[40px] inline-block align-middle leading-[40px] text-center my-0 mx-[5px] relative z-[3] top-1/2 bg-[#fff] text-[#262b35] rounded-[50%] cursor-pointer;
  opacity: 0;
  transform: translateY(15px);
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon a:last-child,
.portfolio-classic .portfolio-image .portfolio-icon > div:last-child {
  transition-delay: 0.2s;
}
.portfolio-classic .portfolio-image .portfolio-icon a i,
.portfolio-classic .portfolio-image .portfolio-icon > div i {
  @apply text-sm;
}
.portfolio-classic .portfolio-caption {
  @apply py-[30px] bg-[#fff] text-center lg:py-[20px];
}
.portfolio-classic .portfolio-caption a span {
  @apply font-medium text-darkgray font-serif;
}

.portfolio-box-boxed {
  @apply relative overflow-hidden;
}
.portfolio-box-boxed img {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-box-boxed:hover img {
  transform: scale(1.1);
}
.portfolio-box-boxed .portfolio-hover {
  @apply absolute left-0 right-0 text-center font-serif z-[999] my-0 mx-auto w-[90%] bottom-0 bg-[#fff] text-[#000] p-[15px] rounded-[3px];
}
.portfolio-box-boxed:hover .portfolio-hover {
  @apply my-[15px] mx-auto;
  transform: translateY(0);
}

.portfolio-boxed .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover {
  @apply left-[20px] bottom-[20px] h-auto;
  opacity: 0;
  width: calc(100% - 40px);
  top: inherit;
  transform: translateY(100%);
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover i {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover:hover i {
  transform: translateX(5px);
}
.portfolio-boxed:hover .portfolio-image img {
  transform: scale(1.1);
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-boxed:hover .porfolio-hover {
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.portfolio-colorful .portfolio-box {
  @apply relative;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover {
  @apply opacity-100;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover i {
  @apply opacity-100 translate-x-0;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content span,
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content h6 {
  transform: translateY(0);
}
.portfolio-colorful .portfolio-box img {
  @apply inline-block w-full h-auto;
}
.portfolio-colorful .portfolio-box .portfolio-hover {
  @apply absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
  background: var(--brand-color);
}
.portfolio-colorful .portfolio-box .portfolio-hover i {
  @apply absolute opacity-0 top-12 right-16 text-[28px] text-white;
  transition: var(--default-transition);
  transform: translateX(15px);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content {
  @apply absolute overflow-hidden text-left bottom-12 left-16;
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content span {
  transform: translateY(-100px);
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content h6 {
  @apply font-semibold text-white font-serif text-[1.8rem] leading-[2.5rem];
  transform: translateY(100px);
  transition: var(--default-transition);
  transition-duration: 0.5s;
}

.portfolio-bordered {
  @apply overflow-hidden;
}
.portfolio-bordered .portfolio-image {
  @apply relative;
  background: var(--overlay-color);
}
.portfolio-bordered .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover {
  @apply flex justify-center items-center bg-white absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover .scale {
  opacity: 0;
  transform: scale3d(1, 1, 1);
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image:hover img {
  opacity: 0.5;
  filter: blur(5px);
  transform: scale(1.1, 1.1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover {
  @apply left-[20px] top-[20px];
  opacity: 1;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  transform: scale3d(1, 1, 1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover .scale {
  opacity: 1;
}

.portfolio-overlay .portfolio-image {
  background: var(--overlay-color, #000);
}
.portfolio-overlay .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover {
  @apply top-0 left-0 w-full h-full opacity-0;
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover i {
  @apply absolute top-2/4 left-2/4;
  transform: translate(-50%, calc(-50% - 15px));
  transition: var(--default-transition);
}
.portfolio-overlay:hover .portfolio-image img {
  transform: scale(1.1);
  opacity: 0.15;
}
.portfolio-overlay:hover .portfolio-hover {
  opacity: 1;
}
.portfolio-overlay:hover .portfolio-hover i {
  transform: translate(-50%, -50%);
}

.portfolio-switchimg {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-switchimg .portfolio-image {
  @apply overflow-hidden relative rounded-[4px];
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image .portfolio-switch-image {
  @apply absolute top-0 left-0 w-full h-full;
  transition: var(--default-transition);
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1.1) translate3d(0, 0, 0);
}
.portfolio-switchimg .portfolio-content {
  transition: var(--default-transition);
}
.portfolio-switchimg:hover {
  transform: translateY(-5px);
}
.portfolio-switchimg:hover .portfolio-switch-image {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.portfolio-switchimg:hover .portfolio-content {
  transform: translateY(-10px);
}

@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+1) .portfolio-box {
    padding: 15% 0;
  }
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+3) .portfolio-box {
    padding: 0 15%;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
  padding: 25% 0;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
    padding: 15% 0;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
  padding: 0 15%;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
    padding: 0 15% 15%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(odd) .portfolio-box {
    padding: 0 0 10%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(even) .portfolio-box {
    padding: 0 10% 10%;
  }
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+1) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+3) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+5) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+4) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+6) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+1) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+3) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered > .col:nth-child(8n+1) .portfolio-box {
  padding: 25% 0;
}
.portfolio-scattered > .col:nth-child(8n+2) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered .portfolio-box .portfolio-image {
  clip-path: inset(0 0 0 0);
  transition: var(--default-transition);
}
.portfolio-scattered .portfolio-box:hover .portfolio-image {
  clip-path: inset(5% 5% 5% 5%);
}
.portfolio-scattered .portfolio-hover {
  transition: var(--default-transition);
}

.portfolio-swiper-slider {
  padding-bottom: 60px;
}
.portfolio-swiper-slider .swiper-button-prev,
.portfolio-swiper-slider .swiper-button-next {
  --swiper-navigation-background: transparent;
  transition: var(--default-transition);
  @apply text-darkgray text-xmd leading-[40px] h-[40px] w-[40px] mr-[5px] rounded-full border top-auto bottom-[10px] -translate-x-1/2;
}
.portfolio-swiper-slider .swiper-button-prev:hover,
.portfolio-swiper-slider .swiper-button-next:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: #fff;
  background: #fff;
}
.portfolio-swiper-slider .swiper-button-prev {
  left: calc(50% - 30px);
}
.portfolio-swiper-slider .swiper-button-next {
  left: calc(50% + 30px);
}

.portfolio-slider {
  transition: var(--default-transition);
  cursor: pointer;
}
.portfolio-slider .portfolio-image {
  transition: var(--default-transition);
}
.portfolio-slider .portfolio-hover {
  transition: var(--default-transition);
  transform: translateY(15px);
  opacity: 0;
}
.portfolio-slider:hover {
  transform: translateY(-5px);
}
.portfolio-slider:hover .portfolio-image {
  background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
  transform: scale(1, 1);
}
.portfolio-slider:hover .portfolio-image img {
  opacity: 0.15;
}
.portfolio-slider:hover .portfolio-hover {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translateY(0);
}

.portfolio-justified {
  overflow: hidden;
  position: static;
}
.portfolio-justified .portfolio-caption {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.portfolio-justified:hover .portfolio-caption {
  opacity: 1;
  visibility: visible;
}

footer {
  @apply relative;
}
footer .text-themecolor {
  @apply text-darkgray;
}
footer.pos-fixed {
  @apply fixed bottom-0 left-0 w-full -z-10;
}
footer .footer-menu span {
  @apply text-darkgray;
}
footer .footer-menu ul,
footer .footer-menu li {
  list-style: none;
}
footer a {
  transition: var(--default-transition);
}
footer a:hover {
  color: var(--base-color, #232323);
}
footer .subtitle {
  color: #232323;
}
footer.dark .text-themecolor {
  @apply text-white;
}
footer.dark .footer-menu span {
  @apply text-lightgray;
}
footer.dark a {
  transition: var(--default-transition);
}
footer.dark a:hover {
  color: #ffffff;
}
footer.dark .subtitle {
  color: #828282;
}

.footer-style-03 form input {
  box-shadow: none;
}
@screen xs {
  .footer-style-03 form button {
    width: auto;
    position: absolute !important;
    top: 0;
    right: 0px;
    bottom: 0;
    border: 0;
    padding: 0px 18px;
    letter-spacing: inherit;
    overflow: hidden;
    height: 46px;
    margin-top: 0;
    border-radius: 2px;
  }
}
.footer-style-03 form button.btn-gradient {
  background-image: linear-gradient(to right, #556fff, #ff798e, #556fff) !important;
  color: #fff;
  background-size: 200% auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.footer-style-03 form button.btn-gradient:hover {
  background-position: right center;
}

.footer-style-04 .subscribe-style-09 form input {
  box-shadow: none;
}
@screen xs {
  .footer-style-04 .subscribe-style-09 form button {
    width: auto;
    position: absolute !important;
    top: 0;
    right: 0px;
    bottom: 0;
    border: 0;
    padding: 0px 18px;
    letter-spacing: inherit;
    overflow: hidden;
    height: 43px;
    margin-top: 0;
    border-radius: 2px;
  }
}
.footer-style-04 .subscribe-style-09 form button.btn-gradient {
  background-image: linear-gradient(to right, #556fff, #ff798e, #556fff) !important;
  color: #fff;
  background-size: 200% auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.footer-style-04 .subscribe-style-09 form button.btn-gradient:hover {
  background-position: right center;
}

@screen xs {
  .footer-style-05 .subscribe-style-09 form button {
    width: auto;
    position: absolute !important;
    top: 0;
    right: 0px;
    bottom: 0;
    border: 0;
    padding: 0px 18px;
    letter-spacing: inherit;
    overflow: hidden;
    height: 44px;
    margin-top: 0;
    border-radius: 2px;
  }
}
.footer-style-05 .subscribe-style-09 form button.btn-gradient {
  background: linear-gradient(to right, #0039e3, #8600d4, #0039e3);
  color: #fff;
  background-size: 200% auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.footer-style-05 .subscribe-style-09 form button.btn-gradient:hover {
  background-position: right center;
}

.footer-style-09 .subscribe-style-05 input {
  padding: 13px 70px 13px 15px;
  font-size: 14px;
}
.footer-style-09 .subscribe-style-05 form button {
  border: none;
  height: 100%;
}

.footer-style-11 .social-icon {
  row-gap: 0;
}

.footer-style-13 .social-icon {
  row-gap: 0;
}

body.mobile-menu {
  transition: var(--default-transition);
}
body.navbar-collapse-show.mobile-menu {
  transition-delay: 0.3s;
}
body.navbar-collapse-show header.reverse-scroll.sticky-header {
  transform: translateY(0);
}

header .top-bar {
  transition: var(--default-transition);
}
header .dropdown .dropdown-menu {
  @apply p-[15px] opacity-0 invisible;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  transition: var(--default-transition);
}
header .dropdown .dropdown-menu a {
  @apply flex items-center;
}
header .dropdown .dropdown-menu a span {
  display: inline-block;
  margin-right: 10px;
}
header .dropdown:hover .dropdown-menu {
  @apply opacity-100 visible;
}
header .header-search-iconbar > a {
  @apply text-darkgray cursor-pointer;
  transition: var(--default-transition);
}
header .header-search-iconbar > a:hover {
  color: rgba(0, 0, 0, 0.6);
}
header .header-language {
  @apply relative;
}
header .header-language > a {
  @apply text-darkgray cursor-pointer;
  transition: var(--default-transition);
}
header .header-language > a:hover {
  color: rgba(0, 0, 0, 0.6);
}
header .header-language .dropdown-menu a {
  @apply text-xs;
  line-height: 30px;
  padding: 2px 0;
  color: #828282;
  transition: var(--default-transition);
}
header .header-language .dropdown-menu a:hover {
  @apply text-darkgray;
}
header .header-language .dropdown-menu a span {
  @apply w-[16px] h-[16px] inline-block;
  margin: 0 10px 0 5px;
}
header .header-language .dropdown-menu.show {
  @apply opacity-100 visible;
}
header .header-cart-icon {
  @apply relative;
}
header .header-cart-icon > a {
  @apply text-darkgray;
  transition: var(--default-transition);
}
header .header-cart-icon .cart-item-list {
  @apply left-auto right-0 rounded-[4px] border-0 p-0 min-w-[250px] m-0;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}
header .header-cart-icon .cart-item-list .cart-item {
  @apply flex p-[17px] border-b border-[#ededed] relative;
}
header .header-cart-icon .cart-item-list .cart-item:last-child {
  border-bottom: none;
  border-radius: 0 0 6px 6px;
}
header .header-cart-icon .cart-item-list .cart-item > .close {
  @apply text-[#838383] text-xmd leading-[15px] absolute right-[15px] top-[15px] font-normal;
  transition: var(--default-transition);
}
header .header-cart-icon .cart-item-list .cart-item > .close:hover {
  @apply text-darkgray;
}
header .header-cart-icon .cart-item-list .cart-item .product-image {
  @apply max-w-[50px] w-[30%] inline-block;
}
header .header-cart-icon .cart-item-list .cart-item .product-detail {
  @apply pl-[15px] w-[70%] inline-block;
}
header .header-cart-icon .cart-item-list .cart-item .product-detail > a {
  @apply text-darkgray text-xs leading-[16px] inline-block;
}
header .header-cart-icon .cart-item-list .cart-item .product-detail .item-ammount {
  @apply block text-xxs text-[#828282] leading-normal;
}
header .header-cart-icon .cart-item-list .cart-total {
  @apply bg-lightgray flex-col;
}
header .header-cart-icon .cart-item-list .cart-total .btn {
  @apply w-full my-[4px] block;
}
header .header-cart-icon > a:hover {
  color: rgba(35, 35, 35, 0.6);
  cursor: pointer;
}
header [class^=container-] [class*=" col-"],
header [class^=container-] [class^=col-],
header [class*=" container-"] [class*=" col-"],
header [class*=" container-"] [class^=col-] {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}
header .nav-item {
  @apply relative font-serif text-md;
}
header .nav-item > i {
  display: none;
}
header .nav-item:hover .megamenu {
  @apply opacity-100 visible;
}
header .nav-item:hover > .simple-dropdown-menu {
  @apply opacity-100 visible;
}
header .nav-item:hover > .simple-dropdown-menu > li:hover > .simple-dropdown-menu {
  @apply opacity-100 visible;
}
header .nav-item:hover > .simple-dropdown-menu > li:hover > .simple-dropdown-menu > li:hover > .simple-dropdown-menu {
  @apply opacity-100 visible;
}
header .nav-item .simple-dropdown-menu {
  @apply text-sm font-medium absolute top-full left-0 z-10 bg-white box-border;
  opacity: 0;
  visibility: hidden;
  line-height: 20px;
  width: 240px;
  padding: 25px 0;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
  transition: none;
}
header .nav-item .simple-dropdown-menu li {
  padding: 9px 0 9px 35px;
  @apply block relative;
}
header .nav-item .simple-dropdown-menu li > .nav-link {
  @apply block p-0 cursor-pointer;
}
header .nav-item .simple-dropdown-menu li > .nav-link > i {
  @apply absolute mt-0 text-center top-1/2 -translate-y-1/2;
  display: inline-block;
  right: 23px;
  font-size: 12px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  transition: var(--default-transition);
}
header .nav-item .simple-dropdown-menu li > .nav-link:hover {
  @apply text-darkgray;
}
header .nav-item .simple-dropdown-menu li > .simple-dropdown-menu {
  @apply left-full top-0 translate-x-0;
}
header .nav-item .simple-dropdown-menu li > .simple-dropdown-menu.menu-left {
  @apply right-full;
  left: auto !important;
}
header .nav-item .simple-dropdown-menu .simple-dropdown.active > .nav-link {
  @apply text-darkgray;
}
header .nav-item .nav-link {
  color: #828282;
}
header .nav-item .nav-link.active {
  @apply text-darkgray;
}
header .navbar-nav {
  @apply flex justify-center;
}
header .navbar-nav > .nav-item > .nav-link {
  padding: 30px 0;
  margin: 0 18px;
  word-break: normal;
  @apply text-darkgray font-medium;
  transition: var(--default-transition);
}
header .navbar-nav > .nav-item.active > .nav-link {
  opacity: 0.7;
}
header .navbar-nav .megamenu {
  @apply absolute top-full z-10 bg-white box-border;
  left: 0;
  transform: inherit;
  opacity: 0;
  visibility: hidden;
  padding: 60px 80px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
  transition: 0s;
}
header .navbar-nav .megamenu ul {
  padding-right: 90px;
}
header .navbar-nav .megamenu ul .title {
  @apply text-darkgray;
}
header .navbar-nav .megamenu ul:last-child {
  padding-right: 50px;
}
@screen lg {
  header .navbar-nav .megamenu ul {
    padding-right: 75px;
  }
}
header .navbar-nav .megamenu .nav-item .nav-link {
  @apply font-serif font-medium flex items-center text-left text-sm p-0 relative overflow-hidden whitespace-nowrap;
  color: #828282;
  margin: 0 0 5px 0;
  line-height: 30px;
  transition: var(--default-transition);
}
header .navbar-nav .megamenu .nav-item .nav-link:hover {
  @apply text-darkgray;
}
header .navbar-nav .megamenu .nav-item.active > .nav-link {
  @apply text-darkgray;
}
@screen lg {
  header .navbar-nav .megamenu {
    padding: 35px 50px 40px;
  }
}
header .navbar-nav .submenu .nav-link {
  @apply p-0;
}
header .navbar-nav .submenu .nav-link:hover {
  @apply text-darkgray;
}
header .navbar-brand img {
  max-height: 100px;
}
header .navbar-brand .default-logo {
  visibility: visible;
  opacity: 1;
  width: auto;
  transition-duration: 0.5s;
}
header .navbar-brand .alt-logo {
  display: none;
  opacity: 0;
  width: 0;
  transition-duration: 0.5s;
}
header .navbar-brand .mobile-logo {
  display: none;
  opacity: 0;
  width: 0;
  transition-duration: 0.5s;
}
header .navbar-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-lg-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-md-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-sm-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-xs-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-xl-dark .navbar-nav > .nav-item > .nav-link,
header .navbar-xxl-dark .navbar-nav > .nav-item > .nav-link {
  @apply text-white;
}
header .navbar-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-lg-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-md-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-sm-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-xs-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-xl-dark .navbar-nav > .nav-item > .nav-link:hover,
header .navbar-xxl-dark .navbar-nav > .nav-item > .nav-link:hover {
  @apply opacity-60;
}
header .navbar-dark .header-search-iconbar > a,
header .navbar-dark .header-language > a,
header .navbar-dark .header-cart-icon > a,
header .navbar-lg-dark .header-search-iconbar > a,
header .navbar-lg-dark .header-language > a,
header .navbar-lg-dark .header-cart-icon > a,
header .navbar-md-dark .header-search-iconbar > a,
header .navbar-md-dark .header-language > a,
header .navbar-md-dark .header-cart-icon > a,
header .navbar-sm-dark .header-search-iconbar > a,
header .navbar-sm-dark .header-language > a,
header .navbar-sm-dark .header-cart-icon > a,
header .navbar-xs-dark .header-search-iconbar > a,
header .navbar-xs-dark .header-language > a,
header .navbar-xs-dark .header-cart-icon > a,
header .navbar-xl-dark .header-search-iconbar > a,
header .navbar-xl-dark .header-language > a,
header .navbar-xl-dark .header-cart-icon > a,
header .navbar-xxl-dark .header-search-iconbar > a,
header .navbar-xxl-dark .header-language > a,
header .navbar-xxl-dark .header-cart-icon > a {
  @apply text-white;
  display: inline-block;
  vertical-align: middle;
  min-height: 20px;
  width: 20px;
}
header .navbar-dark .header-search-iconbar > a:hover,
header .navbar-dark .header-language > a:hover,
header .navbar-dark .header-cart-icon > a:hover,
header .navbar-lg-dark .header-search-iconbar > a:hover,
header .navbar-lg-dark .header-language > a:hover,
header .navbar-lg-dark .header-cart-icon > a:hover,
header .navbar-md-dark .header-search-iconbar > a:hover,
header .navbar-md-dark .header-language > a:hover,
header .navbar-md-dark .header-cart-icon > a:hover,
header .navbar-sm-dark .header-search-iconbar > a:hover,
header .navbar-sm-dark .header-language > a:hover,
header .navbar-sm-dark .header-cart-icon > a:hover,
header .navbar-xs-dark .header-search-iconbar > a:hover,
header .navbar-xs-dark .header-language > a:hover,
header .navbar-xs-dark .header-cart-icon > a:hover,
header .navbar-xl-dark .header-search-iconbar > a:hover,
header .navbar-xl-dark .header-language > a:hover,
header .navbar-xl-dark .header-cart-icon > a:hover,
header .navbar-xxl-dark .header-search-iconbar > a:hover,
header .navbar-xxl-dark .header-language > a:hover,
header .navbar-xxl-dark .header-cart-icon > a:hover {
  color: rgba(255, 255, 255, 0.6);
}
header .navbar-dark .navbar-toggler .navbar-toggler-line,
header .navbar-lg-dark .navbar-toggler .navbar-toggler-line,
header .navbar-md-dark .navbar-toggler .navbar-toggler-line,
header .navbar-sm-dark .navbar-toggler .navbar-toggler-line,
header .navbar-xs-dark .navbar-toggler .navbar-toggler-line,
header .navbar-xl-dark .navbar-toggler .navbar-toggler-line,
header .navbar-xxl-dark .navbar-toggler .navbar-toggler-line {
  background-color: #fff;
}
header .menu-dark .megamenu {
  @apply bg-darkgray;
}
header .menu-dark .megamenu ul .title {
  @apply text-white;
}
header .menu-dark .megamenu .nav-item .nav-link:hover {
  @apply text-white;
}
header .menu-dark .megamenu .nav-item.active .nav-link {
  @apply text-white;
}
header .menu-dark .simple-dropdown-menu,
header .menu-dark .dropdown-menu {
  @apply bg-darkgray;
}
header .menu-dark .simple-dropdown-menu > li .nav-link,
header .menu-dark .dropdown-menu > li .nav-link {
  transition: var(--default-transition);
}
header .menu-dark .simple-dropdown-menu > li .nav-link:hover,
header .menu-dark .dropdown-menu > li .nav-link:hover {
  @apply text-white;
}
header .menu-dark .simple-dropdown-menu > li a,
header .menu-dark .dropdown-menu > li a {
  transition: var(--default-transition);
}
header .menu-dark .simple-dropdown-menu > li a:hover,
header .menu-dark .dropdown-menu > li a:hover {
  @apply text-white;
}
header .collapsible-menu {
  @apply font-serif;
}
header .collapsible-menu .accordion-item {
  @apply border-none;
  background-color: transparent;
}
header .collapsible-menu .accordion-item:hover > .accordion-header .accordion-button, header .collapsible-menu .accordion-item.active > .accordion-header .accordion-button {
  color: rgba(0, 0, 0, 0.6);
}
header .collapsible-menu .accordion-item .img-wrapper {
  border-style: none;
  display: none;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button {
  @apply font-serif text-xlg leading-normal text-darkgray;
  padding: 13px 0;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button::after {
  @apply hidden;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button:hover span,
header .collapsible-menu .accordion-item .accordion-header .accordion-button:hover a {
  transition: all 0.3s ease-in-out;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button .icon {
  @apply inline-block ml-auto relative;
  width: 30px;
  height: 30px;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button .icon:before, header .collapsible-menu .accordion-item .accordion-header .accordion-button .icon:after {
  @apply inline-block absolute top-1/2 bg-darkgray;
  content: "";
  width: 11px;
  height: 1px;
  left: 10px;
  transition: var(--default-transition);
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button .icon::after {
  @apply bg-none rotate-90;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  @apply bg-transparent shadow-none;
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button:not(.collapsed) > .icon::after {
  transform: rotate(0deg);
}
header .collapsible-menu .accordion-item .accordion-header .accordion-button:not(.collapsed) > .icon:before {
  @apply bg-transparent;
}
header .collapsible-menu .accordion-item .accordion-collapse .accordion-body {
  color: rgba(0, 0, 0, 0.6);
  @apply text-sm py-[5px] px-0;
}
header .collapsible-menu .accordion-item .accordion-collapse .accordion-body i {
  @apply inline-block mr-[10px] align-middle;
}
header .collapsible-menu .accordion-item .accordion-collapse .accordion-body .accordion-button {
  @apply text-sm py-0 px-0;
  line-height: 30px;
}
header .collapsible-menu .menu-list {
  padding-left: 15px;
}
header .collapsible-menu .menu-list .menu-list-item {
  line-height: 30px;
}
header .collapsible-menu .menu-list .menu-list-item a {
  color: rgba(0, 0, 0, 0.6);
}
header .collapsible-menu .menu-list .menu-list-item a:hover {
  color: rgba(0, 0, 0, 0.6);
}
header .collapsible-menu .menu-list .menu-list-item.active a {
  color: rgba(0, 0, 0, 0.3);
}
header .collapsible-menu .megamenu .accordion .accordion-item {
  border: 0px !important;
}
header .collapsible-menu .megamenu .accordion .accordion-item:hover .accordion-header .accordion-button {
  color: #828282 !important;
}
header .collapsible-menu .single-dropdown a.menu-link {
  color: rgba(0, 0, 0, 0.6);
}
header .collapsible-menu .single-dropdown a.menu-link:hover {
  color: rgba(0, 0, 0, 0.3);
}
header .collapsible-menu .single-dropdown .accordion > .accordion-item {
  border: 0px !important;
}
header .collapsible-menu .single-dropdown .accordion-item .accordion-item a.menu-link {
  padding-left: 15px;
}
header .collapsible-menu .footer-holder .social-icon li a:hover {
  transform: translateY(0px);
}
header .collapsible-menu.light .menu-list .menu-list-item a {
  color: rgba(255, 255, 255, 0.6);
}
header .collapsible-menu.light .menu-list .menu-list-item.active a {
  color: rgba(255, 255, 255, 0.3);
}
header .collapsible-menu.light .accordion-item .accordion-header .accordion-button {
  color: #fff;
}
header .collapsible-menu.light .accordion-item .accordion-header .accordion-button .icon::after, header .collapsible-menu.light .accordion-item .accordion-header .accordion-button .icon::before {
  background-color: #fff;
}
header .collapsible-menu.light .accordion-item.active > .accordion-header .accordion-button, header .collapsible-menu.light .accordion-item:hover > .accordion-header .accordion-button {
  color: rgba(255, 255, 255, 0.5);
}
header .collapsible-menu.light .single-dropdown .menu-list-item.active a.menu-link {
  color: rgba(255, 255, 255, 0.3);
}
header .collapsible-menu.light .single-dropdown a.menu-link {
  color: rgba(255, 255, 255, 0.6);
}
header.always-fixed {
  @apply fixed top-0 left-0 w-full;
  z-index: 10;
}
header.always-fixed.sticky-header {
  @apply translate-y-0;
}
header.reverse-scroll {
  @apply fixed top-0 left-0 w-full;
  z-index: 15;
  transition: var(--default-transition);
}
header.reverse-scroll.sticky-header {
  @apply -translate-y-full;
  box-shadow: none;
}
header.reverse-scroll.sticky-header.header-appear {
  @apply bg-white translate-y-0;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}
header.reverse-scroll.sticky-header.header-appear .alt-logo {
  display: block;
  opacity: 1;
  width: auto;
}
header.reverse-scroll.sticky-header.header-appear .default-logo {
  opacity: 0;
  display: none;
  width: 0;
}
header.reverse-scroll.sticky-header.header-appear .menu-light .header-search-iconbar > a,
header.reverse-scroll.sticky-header.header-appear .menu-light .header-language > a,
header.reverse-scroll.sticky-header.header-appear .menu-light .header-cart-icon > a,
header.reverse-scroll.sticky-header.header-appear .menu-light .nav-bar-contact > a {
  @apply text-darkgray;
}
header.reverse-scroll.sticky-header.header-appear .menu-light .header-search-iconbar > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light .header-language > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light .header-cart-icon > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light .nav-bar-contact > a:hover {
  color: rgba(35, 35, 35, 0.6);
}
header.reverse-scroll.sticky-header.header-appear .menu-light .social-icon.light a {
  @apply text-darkgray;
}
header.reverse-scroll.sticky-header.header-appear .menu-light .social-icon.light a:hover {
  color: rgba(35, 35, 35, 0.6);
}
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-search-iconbar > a,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-language > a,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-cart-icon > a, header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-search-iconbar > a,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-language > a,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-cart-icon > a {
  @apply text-white;
}
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-search-iconbar > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-language > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-dark .header-cart-icon > a:hover, header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-search-iconbar > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-language > a:hover,
header.reverse-scroll.sticky-header.header-appear .menu-light.bg-black .header-cart-icon > a:hover {
  color: rgba(255, 255, 255, 0.6);
}
header.reverse-scroll.sticky-header.header-appear .menu-dark {
  @apply bg-darkgray;
}
header.reverse-scroll.sticky-header.header-appear .menu-dark:not(.bg-light) .navbar-brand img {
  filter: brightness(0) invert(1);
}
header.reverse-scroll.sticky-header.header-appear > .header-transparent .navbar-nav > .nav-item > .nav-link {
  @apply text-darkgray;
}
header.reverse-scroll.sticky-header.header-appear > .header-transparent .header-push-button .push-button span {
  @apply bg-darkgray;
}
header.reverse-scroll.sticky-header.header-appear .top-bar {
  margin-top: calc(-1px - var(--topbar-height));
}
header.responsive-sticky {
  @apply fixed top-0 left-0 w-full;
  z-index: 15;
}
header.responsive-sticky .header-search-iconbar i,
header.responsive-sticky .header-language i,
header.responsive-sticky .header-cart-icon i {
  padding: 30px 0;
  transition: var(--default-transition);
}
header.responsive-sticky > .navbar-expand-lg, header.responsive-sticky > .navbar-expand-sm, header.responsive-sticky > .navbar-expand-md {
  transition: var(--default-transition);
}
@screen md {
  header.responsive-sticky > .navbar-expand-lg, header.responsive-sticky > .navbar-expand-sm, header.responsive-sticky > .navbar-expand-md {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
header.responsive-sticky.sticky-header .navbar-nav > .nav-item > .nav-link {
  padding: 18px 0;
}
header.responsive-sticky.sticky-header .header-search-iconbar i,
header.responsive-sticky.sticky-header .header-language i,
header.responsive-sticky.sticky-header .header-cart-icon i {
  padding: 18px 0;
}
@screen md {
  header.responsive-sticky.sticky-header > .navbar-expand-lg, header.responsive-sticky.sticky-header > .navbar-expand-sm, header.responsive-sticky.sticky-header > .navbar-expand-md {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header.responsive-sticky.sticky-header > .navbar-expand-lg > .nav-item > .nav-link, header.responsive-sticky.sticky-header > .navbar-expand-sm > .nav-item > .nav-link, header.responsive-sticky.sticky-header > .navbar-expand-md > .nav-item > .nav-link {
    padding: 15px 0;
  }
}
header.pos-left {
  @apply fixed top-0 left-0;
}
@screen md {
  header .navbar-expand-lg.header-transparent, header .header-transparent.navbar-expand-sm, header .header-transparent.navbar-expand-md {
    background-color: #F2EFEB !important;
  }
  header .navbar-expand-lg .navbar-brand .default-logo, header .navbar-expand-sm .navbar-brand .default-logo, header .navbar-expand-md .navbar-brand .default-logo,
  header .navbar-expand-lg .navbar-brand .alt-logo,
  header .navbar-expand-sm .navbar-brand .alt-logo,
  header .navbar-expand-md .navbar-brand .alt-logo {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    width: 0 !important;
  }
  header .navbar-expand-lg .navbar-brand .mobile-logo, header .navbar-expand-sm .navbar-brand .mobile-logo, header .navbar-expand-md .navbar-brand .mobile-logo {
    display: block;
    opacity: 1;
    visibility: visible;
    width: auto;
  }
  header .navbar-expand-lg.navbar-dark .header-search-iconbar > a, header .navbar-dark.navbar-expand-sm .header-search-iconbar > a, header .navbar-dark.navbar-expand-md .header-search-iconbar > a,
  header .navbar-expand-lg.navbar-dark .header-search-iconbar span,
  header .navbar-dark.navbar-expand-sm .header-search-iconbar span,
  header .navbar-dark.navbar-expand-md .header-search-iconbar span,
  header .navbar-expand-lg.navbar-dark .header-language > a,
  header .navbar-dark.navbar-expand-sm .header-language > a,
  header .navbar-dark.navbar-expand-md .header-language > a,
  header .navbar-expand-lg.navbar-dark .header-language span,
  header .navbar-dark.navbar-expand-sm .header-language span,
  header .navbar-dark.navbar-expand-md .header-language span,
  header .navbar-expand-lg.navbar-dark .header-cart-icon > a,
  header .navbar-dark.navbar-expand-sm .header-cart-icon > a,
  header .navbar-dark.navbar-expand-md .header-cart-icon > a,
  header .navbar-expand-lg.navbar-dark .header-cart-icon span,
  header .navbar-dark.navbar-expand-sm .header-cart-icon span,
  header .navbar-dark.navbar-expand-md .header-cart-icon span {
    @apply text-darkgray;
  }
  header .navbar-expand-lg.navbar-dark.header-transparent .navbar-toggler .navbar-toggler-line, header .navbar-dark.header-transparent.navbar-expand-sm .navbar-toggler .navbar-toggler-line, header .navbar-dark.header-transparent.navbar-expand-md .navbar-toggler .navbar-toggler-line {
    @apply bg-darkgray;
  }
  header .navbar-expand-lg.navbar-dark.header-transparent .social-icon.light a, header .navbar-dark.header-transparent.navbar-expand-sm .social-icon.light a, header .navbar-dark.header-transparent.navbar-expand-md .social-icon.light a {
    @apply text-darkgray;
  }
  header .navbar-expand-lg.navbar-dark.bg-dark .header-search-iconbar > a, header .navbar-dark.bg-dark.navbar-expand-sm .header-search-iconbar > a, header .navbar-dark.bg-dark.navbar-expand-md .header-search-iconbar > a,
  header .navbar-expand-lg.navbar-dark.bg-dark .header-language > a,
  header .navbar-dark.bg-dark.navbar-expand-sm .header-language > a,
  header .navbar-dark.bg-dark.navbar-expand-md .header-language > a,
  header .navbar-expand-lg.navbar-dark.bg-dark .header-cart-icon > a,
  header .navbar-dark.bg-dark.navbar-expand-sm .header-cart-icon > a,
  header .navbar-dark.bg-dark.navbar-expand-md .header-cart-icon > a, header .navbar-expand-lg.navbar-dark.bg-black .header-search-iconbar > a, header .navbar-dark.bg-black.navbar-expand-sm .header-search-iconbar > a, header .navbar-dark.bg-black.navbar-expand-md .header-search-iconbar > a,
  header .navbar-expand-lg.navbar-dark.bg-black .header-language > a,
  header .navbar-dark.bg-black.navbar-expand-sm .header-language > a,
  header .navbar-dark.bg-black.navbar-expand-md .header-language > a,
  header .navbar-expand-lg.navbar-dark.bg-black .header-cart-icon > a,
  header .navbar-dark.bg-black.navbar-expand-sm .header-cart-icon > a,
  header .navbar-dark.bg-black.navbar-expand-md .header-cart-icon > a {
    @apply text-white;
  }
  header .navbar-expand-lg.navbar-dark.bg-dark .navbar-toggler .navbar-toggler-line, header .navbar-dark.bg-dark.navbar-expand-sm .navbar-toggler .navbar-toggler-line, header .navbar-dark.bg-dark.navbar-expand-md .navbar-toggler .navbar-toggler-line, header .navbar-expand-lg.navbar-dark.bg-black .navbar-toggler .navbar-toggler-line, header .navbar-dark.bg-black.navbar-expand-sm .navbar-toggler .navbar-toggler-line, header .navbar-dark.bg-black.navbar-expand-md .navbar-toggler .navbar-toggler-line {
    @apply bg-white;
  }
  header .navbar-expand-lg.menu-dark .navbar-collapse, header .menu-dark.navbar-expand-sm .navbar-collapse, header .menu-dark.navbar-expand-md .navbar-collapse {
    @apply bg-darkgray;
  }
  header .navbar-expand-lg.menu-dark .navbar-nav > .nav-item > .nav-link, header .menu-dark.navbar-expand-sm .navbar-nav > .nav-item > .nav-link, header .menu-dark.navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    color: #fff;
  }
  header .navbar-expand-lg .navbar-collapse, header .navbar-expand-sm .navbar-collapse, header .navbar-expand-md .navbar-collapse {
    @apply absolute top-full left-0 w-full overflow-hidden bg-white;
    box-shadow: 0 20px 15px 0 rgba(23, 23, 23, 0.05);
    max-height: calc(100vh - 65px);
  }
  @screen sm {
    header .navbar-expand-lg .navbar-collapse, header .navbar-expand-sm .navbar-collapse, header .navbar-expand-md .navbar-collapse {
      padding: 0;
    }
  }
  header .navbar-expand-lg .navbar-collapse.show, header .navbar-expand-sm .navbar-collapse.show, header .navbar-expand-md .navbar-collapse.show {
    /* BACKGROUND HEADER */
    /* background-color: #F2EFEB; */
    @apply overflow-y-auto;
  }
  header .navbar-expand-lg .navbar-collapse.show.mobile-menu-full, header .navbar-expand-sm .navbar-collapse.show.mobile-menu-full, header .navbar-expand-md .navbar-collapse.show.mobile-menu-full {
    transform: translateY(0);
  }
  header .navbar-expand-lg .navbar-collapse.show.mobile-menu-full .navbar-nav > .nav-item > .nav-link, header .navbar-expand-sm .navbar-collapse.show.mobile-menu-full .navbar-nav > .nav-item > .nav-link, header .navbar-expand-md .navbar-collapse.show.mobile-menu-full .navbar-nav > .nav-item > .nav-link {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  header .navbar-expand-lg .navbar-collapse.show.mobile-menu-full .navbar-nav .nav-item:last-child, header .navbar-expand-sm .navbar-collapse.show.mobile-menu-full .navbar-nav .nav-item:last-child, header .navbar-expand-md .navbar-collapse.show.mobile-menu-full .navbar-nav .nav-item:last-child {
    border-bottom: 0px;
  }
  header .navbar-expand-lg .navbar-collapse.show.mobile-menu-modern, header .navbar-expand-sm .navbar-collapse.show.mobile-menu-modern, header .navbar-expand-md .navbar-collapse.show.mobile-menu-modern {
    @apply pb-[75px] pt-[94px] translate-x-0;
    padding-left: 12vw;
    padding-right: 12vw;
  }
  @screen xs {
    header .navbar-expand-lg .navbar-collapse.show.mobile-menu-modern, header .navbar-expand-sm .navbar-collapse.show.mobile-menu-modern, header .navbar-expand-md .navbar-collapse.show.mobile-menu-modern {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }
  header .navbar-expand-lg .navbar-collapse:not(.show).mobile-menu-modern, header .navbar-expand-sm .navbar-collapse:not(.show).mobile-menu-modern, header .navbar-expand-md .navbar-collapse:not(.show).mobile-menu-modern {
    transition-delay: 0.1s;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full, header .navbar-expand-sm .navbar-collapse.mobile-menu-full, header .navbar-expand-md .navbar-collapse.mobile-menu-full {
    @apply fixed top-0 left-0 sm:p-[70px] xs:p-[50px] xs:pb-[70px] xs:pr-[35px] w-full;
    padding: 100px 70px;
    height: 100vh;
    max-height: 100vh;
    background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
    z-index: 1;
    display: block !important;
    transform: translateY(-100%);
    transition: var(--default-transition);
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-full .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link {
    color: white !important;
    opacity: 0.7 !important;
    font-size: 14px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-nav, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-nav, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-nav {
    @apply block overflow-auto px-[25px] xs:px-[5px] h-full;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-nav .nav-item, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-nav .nav-item, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-nav .nav-item {
    @apply pt-[10px] pb-[12px] block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link {
    color: white !important;
    font-size: 17px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link.active, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link.active, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-nav .nav-item .nav-link.active {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-nav .nav-item i, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-nav .nav-item i, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-nav .nav-item i {
    font-size: 17px;
    color: white !important;
    top: 7px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .navbar-toggler, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .navbar-toggler, header .navbar-expand-md .navbar-collapse.mobile-menu-full .navbar-toggler {
    @apply top-[30px] right-[30px] absolute;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .megamenu, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .megamenu, header .navbar-expand-md .navbar-collapse.mobile-menu-full .megamenu {
    @apply bg-transparent;
    left: 0 !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .megamenu .title, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .megamenu .title, header .navbar-expand-md .navbar-collapse.mobile-menu-full .megamenu .title {
    @apply pb-[12px] text-[15px] opacity-70 mb-[4px] text-white;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .megamenu .nav-item, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .megamenu .nav-item, header .navbar-expand-md .navbar-collapse.mobile-menu-full .megamenu .nav-item {
    @apply pb-[12px] pt-0 border-b-0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .megamenu .nav-item .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .megamenu .nav-item .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-full .megamenu .nav-item .nav-link {
    font-size: 13px;
    line-height: normal;
    margin-bottom: 5px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu {
    @apply bg-transparent;
    left: 0 !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link {
    @apply pb-[12px] text-[15px] mb-[4px] text-white;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown {
    @apply pb-[12px] border-b-0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link {
    font-size: 13px;
    line-height: normal;
    margin: 0;
    padding: 0;
    color: white;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link.active, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link.active, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown > .nav-link.active {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu, header .navbar-expand-sm .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu, header .navbar-expand-md .navbar-collapse.mobile-menu-full .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu {
    margin-top: 15px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern, header .navbar-expand-md .navbar-collapse.mobile-menu-modern {
    @apply xs:translate-x-[70vw] fixed top-0 left-auto w-[70vw] xs:w-[85vw] right-0 py-[100px] px-[12vw]  xs:px-[10vw] xs:py-[30px] block;
    transform: translateX(calc(70vw + 10px));
    height: 100vh;
    max-height: 100vh;
    background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
    z-index: 1;
    transition: var(--default-transition);
  }
  @screen xs {
    header .navbar-expand-lg .navbar-collapse.mobile-menu-modern, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern, header .navbar-expand-md .navbar-collapse.mobile-menu-modern {
      transform: translateX(calc(85vw + 10px));
    }
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown {
    margin-bottom: 25px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown:last-child, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown:last-child, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown:last-child {
    margin-bottom: 0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .dropdown > .simple-dropdown-menu > .simple-dropdown > .nav-link {
    font-size: 14px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-nav, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-nav, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-nav {
    @apply block overflow-auto h-full p-0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item {
    @apply pt-[10px] pb-[12px] block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item:last-child, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item:last-child, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item:last-child {
    border-bottom: 0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item .nav-link {
    color: white !important;
    font-size: 17px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item i, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item i, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-nav .nav-item i {
    color: white;
    top: 10px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .navbar-toggler, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .navbar-toggler, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .navbar-toggler {
    @apply top-[30px] right-[30px] absolute;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .megamenu, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .megamenu, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .megamenu {
    @apply bg-transparent;
    left: 0 !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .megamenu .title, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .megamenu .title, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .megamenu .title {
    @apply text-[15px] mb-[15px] opacity-70 text-white;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .megamenu .nav-item, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .megamenu .nav-item, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .megamenu .nav-item {
    @apply pb-[10px] pt-0 border-b-0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link {
    font-size: 13px;
    line-height: normal;
    margin-bottom: 5px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link.active, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link.active, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .megamenu .nav-item .nav-link.active {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu {
    background: transparent;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown {
    @apply border-b-0;
    margin-bottom: 10px;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown:last-child, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown:last-child, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown:last-child {
    margin-bottom: 0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link {
    font-size: 13px;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link.active, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link.active, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > .nav-link.active {
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.7);
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > span.nav-link, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > span.nav-link, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown > span.nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  header .navbar-expand-lg .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu, header .navbar-expand-sm .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu, header .navbar-expand-md .navbar-collapse.mobile-menu-modern .simple-dropdown-menu .simple-dropdown .simple-dropdown-menu {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu, header .navbar-expand-sm .nav-item .simple-dropdown-menu, header .navbar-expand-md .nav-item .simple-dropdown-menu,
  header .navbar-expand-lg .nav-item .megamenu,
  header .navbar-expand-sm .nav-item .megamenu,
  header .navbar-expand-md .nav-item .megamenu {
    @apply hidden relative w-full shadow-none translate-x-0;
    left: 0;
    padding: 0 0 20px 15px;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu li, header .navbar-expand-sm .nav-item .simple-dropdown-menu li, header .navbar-expand-md .nav-item .simple-dropdown-menu li,
  header .navbar-expand-lg .nav-item .megamenu li,
  header .navbar-expand-sm .nav-item .megamenu li,
  header .navbar-expand-md .nav-item .megamenu li {
    @apply p-0;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu li > .simple-dropdown-menu, header .navbar-expand-sm .nav-item .simple-dropdown-menu li > .simple-dropdown-menu, header .navbar-expand-md .nav-item .simple-dropdown-menu li > .simple-dropdown-menu,
  header .navbar-expand-lg .nav-item .simple-dropdown-menu li > .megamenu,
  header .navbar-expand-sm .nav-item .simple-dropdown-menu li > .megamenu,
  header .navbar-expand-md .nav-item .simple-dropdown-menu li > .megamenu,
  header .navbar-expand-lg .nav-item .megamenu li > .simple-dropdown-menu,
  header .navbar-expand-sm .nav-item .megamenu li > .simple-dropdown-menu,
  header .navbar-expand-md .nav-item .megamenu li > .simple-dropdown-menu,
  header .navbar-expand-lg .nav-item .megamenu li > .megamenu,
  header .navbar-expand-sm .nav-item .megamenu li > .megamenu,
  header .navbar-expand-md .nav-item .megamenu li > .megamenu {
    @apply left-0 p-0;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu li > span, header .navbar-expand-sm .nav-item .simple-dropdown-menu li > span, header .navbar-expand-md .nav-item .simple-dropdown-menu li > span,
  header .navbar-expand-lg .nav-item .megamenu li > span,
  header .navbar-expand-sm .nav-item .megamenu li > span,
  header .navbar-expand-md .nav-item .megamenu li > span {
    color: #232323;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu li > span i, header .navbar-expand-sm .nav-item .simple-dropdown-menu li > span i, header .navbar-expand-md .nav-item .simple-dropdown-menu li > span i,
  header .navbar-expand-lg .nav-item .megamenu li > span i,
  header .navbar-expand-sm .nav-item .megamenu li > span i,
  header .navbar-expand-md .nav-item .megamenu li > span i {
    display: none;
  }
  header .navbar-expand-lg .nav-item .simple-dropdown-menu .nav-link, header .navbar-expand-sm .nav-item .simple-dropdown-menu .nav-link, header .navbar-expand-md .nav-item .simple-dropdown-menu .nav-link,
  header .navbar-expand-lg .nav-item .megamenu .nav-link,
  header .navbar-expand-sm .nav-item .megamenu .nav-link,
  header .navbar-expand-md .nav-item .megamenu .nav-link {
    padding: 3px 15px 3px;
    font-size: 14px;
    margin-bottom: 5px;
  }
  header .navbar-expand-lg .nav-item .megamenu > div, header .navbar-expand-sm .nav-item .megamenu > div, header .navbar-expand-md .nav-item .megamenu > div {
    @apply block;
  }
  header .navbar-expand-lg .nav-item .megamenu ul, header .navbar-expand-sm .nav-item .megamenu ul, header .navbar-expand-md .nav-item .megamenu ul {
    @apply block mb-[20px] pr-0;
  }
  @screen md {
    header .navbar-expand-lg .nav-item .megamenu ul.img-wrapper, header .navbar-expand-sm .nav-item .megamenu ul.img-wrapper, header .navbar-expand-md .nav-item .megamenu ul.img-wrapper {
      display: none;
    }
  }
  @screen sm {
    header .navbar-expand-lg .nav-item .megamenu ul.img-wrapper, header .navbar-expand-sm .nav-item .megamenu ul.img-wrapper, header .navbar-expand-md .nav-item .megamenu ul.img-wrapper {
      display: none;
    }
  }
  @screen xs {
    header .navbar-expand-lg .nav-item .megamenu ul.img-wrapper, header .navbar-expand-sm .nav-item .megamenu ul.img-wrapper, header .navbar-expand-md .nav-item .megamenu ul.img-wrapper {
      display: none;
    }
  }
  header .navbar-expand-lg .nav-item .megamenu ul:last-child, header .navbar-expand-sm .nav-item .megamenu ul:last-child, header .navbar-expand-md .nav-item .megamenu ul:last-child {
    @apply mb-0;
  }
  header .navbar-expand-lg .nav-item .megamenu ul .nav-link, header .navbar-expand-sm .nav-item .megamenu ul .nav-link, header .navbar-expand-md .nav-item .megamenu ul .nav-link {
    @apply mb-0 p-0;
  }
  header .navbar-expand-lg .nav-item > .simple-dropdown-menu, header .navbar-expand-sm .nav-item > .simple-dropdown-menu, header .navbar-expand-md .nav-item > .simple-dropdown-menu,
  header .navbar-expand-lg .nav-item > .megamenu,
  header .navbar-expand-sm .nav-item > .megamenu,
  header .navbar-expand-md .nav-item > .megamenu {
    margin-top: 10px;
  }
  header .navbar-expand-lg .nav-item > .simple-dropdown-menu > .simple-dropdown, header .navbar-expand-sm .nav-item > .simple-dropdown-menu > .simple-dropdown, header .navbar-expand-md .nav-item > .simple-dropdown-menu > .simple-dropdown,
  header .navbar-expand-lg .nav-item > .megamenu > .simple-dropdown,
  header .navbar-expand-sm .nav-item > .megamenu > .simple-dropdown,
  header .navbar-expand-md .nav-item > .megamenu > .simple-dropdown {
    margin-bottom: 10px;
  }
  header .navbar-expand-lg .nav-item > .simple-dropdown-menu > .simple-dropdown > a, header .navbar-expand-sm .nav-item > .simple-dropdown-menu > .simple-dropdown > a, header .navbar-expand-md .nav-item > .simple-dropdown-menu > .simple-dropdown > a,
  header .navbar-expand-lg .nav-item > .megamenu > .simple-dropdown > a,
  header .navbar-expand-sm .nav-item > .megamenu > .simple-dropdown > a,
  header .navbar-expand-md .nav-item > .megamenu > .simple-dropdown > a {
    @apply text-darkgray;
  }
  header .navbar-expand-lg .navbar-nav, header .navbar-expand-sm .navbar-nav, header .navbar-expand-md .navbar-nav {
    padding: 15px 30px 25px;
  }
  @screen sm {
    header .navbar-expand-lg .navbar-nav, header .navbar-expand-sm .navbar-nav, header .navbar-expand-md .navbar-nav {
      padding: 15px 15px 25px;
    }
  }
  header .navbar-expand-lg .navbar-nav > .nav-item > .nav-link, header .navbar-expand-sm .navbar-nav > .nav-item > .nav-link, header .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    padding-top: 9px;
    padding-bottom: 9px;
    @apply text-base text-darkgray m-0;
  }
  header .navbar-expand-lg .navbar-nav > .nav-item.dropdown .nav-link, header .navbar-expand-sm .navbar-nav > .nav-item.dropdown .nav-link, header .navbar-expand-md .navbar-nav > .nav-item.dropdown .nav-link {
    position: relative;
  }
  header .navbar-expand-lg .navbar-nav > .nav-item.dropdown > i, header .navbar-expand-sm .navbar-nav > .nav-item.dropdown > i, header .navbar-expand-md .navbar-nav > .nav-item.dropdown > i {
    @apply flex items-center justify-end top-0 right-0 absolute text-right;
    width: 48px;
    height: 48px;
    font-weight: 900;
    line-height: 1;
  }
  header .navbar-expand-lg .navbar-nav > .nav-item.dropdown.open .simple-dropdown-menu, header .navbar-expand-sm .navbar-nav > .nav-item.dropdown.open .simple-dropdown-menu, header .navbar-expand-md .navbar-nav > .nav-item.dropdown.open .simple-dropdown-menu,
  header .navbar-expand-lg .navbar-nav > .nav-item.dropdown.open .megamenu,
  header .navbar-expand-sm .navbar-nav > .nav-item.dropdown.open .megamenu,
  header .navbar-expand-md .navbar-nav > .nav-item.dropdown.open .megamenu {
    @apply block opacity-100 visible;
    left: 0 !important;
    top: 0 !important;
    transform: translateY(0) !important;
  }
}
@screen sm {}
@screen xs {}

.left-menu-modern-sidebar .left-menu-wrapper {
  transform: translateX(-120%);
}
.left-menu-modern-sidebar .left-menu-wrapper.show {
  transform: translateX(0);
}

.header-with-topbar.sticky-header.header-appear.reverse-scroll {
  @apply translate-y-0;
}
.header-with-topbar .dropdown > button::after {
  display: none;
}
.header-with-topbar .dropdown > button:hover {
  border-color: inherit;
  color: inherit;
}
.header-with-topbar .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
.header-with-topbar .show > .btn-primary.dropdown-toggle {
  @apply text-[#828282] block;
}
.header-with-topbar .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.header-with-topbar .dropdown-menu {
  @apply text-left bg-white p-[15px] rounded-[6px] left-auto border-none;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}
.header-with-topbar .dropdown-items:hover {
  @apply bg-transparent;
}

.header-push-button {
  vertical-align: middle;
  padding: 0;
}
.header-push-button.dark .splitportfolio-header li:hover a {
  @apply text-[#00000099] inline;
}
.header-push-button.dark .splitportfolio-header li a {
  @apply text-darkgray;
}
.header-push-button.dark .splitportfolio-header li a::before {
  @apply bg-[#00000099] inline;
}
.header-push-button.dark .close-btn {
  @apply text-black;
}
.header-push-button .close-btn {
  position: absolute;
  font-size: 16px;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  @apply top-[20px] right-[20px] xs:top-0 xs:right-0;
}
.header-push-button .push-button {
  position: relative;
  width: 22px;
  height: 14px;
  display: block;
  top: 1px;
}
.header-push-button .hamburger-menu-wrapper {
  @apply fixed top-0;
  right: -110%;
  min-width: 500px;
  height: 100dvh;
  transition: var(--default-transition);
}
.header-push-button .hamburger-menu-wrapper.show {
  @apply right-0;
}
.header-push-button .hamburger-menu-wrapper.pos-left {
  right: auto;
  left: -110%;
}
.header-push-button .hamburger-menu-wrapper.pos-left.show {
  left: 0;
}

/* Hamburger Menu Button*/
.navbar-expand .navbar-toggler {
  display: block;
}

.navbar-toggler {
  @apply inline-block relative p-0 align-middle rounded-none;
  font-size: 24px;
  width: 22px;
  height: 14px;
  border: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .navbar-toggler-line {
  @apply bg-darkgray block rounded-none absolute left-0 right-0 mx-auto rotate-0;
  content: "";
  height: 2px;
  width: 22px;
  transition: 0.25s ease-in-out;
}
.navbar-toggler .navbar-toggler-line:nth-child(1) {
  top: 0;
  width: 14px;
}
.navbar-toggler .navbar-toggler-line:nth-child(2), .navbar-toggler .navbar-toggler-line:nth-child(3) {
  top: 6px;
}
.navbar-toggler .navbar-toggler-line:nth-child(4) {
  top: 12px;
  width: 14px;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-line:nth-child(1) {
  top: 7px;
  width: 0%;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-line:nth-child(2) {
  @apply rotate-45;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-line:nth-child(3) {
  @apply -rotate-45;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-line:nth-child(4) {
  top: 7px;
  width: 0%;
}

.navbar-expand .navbar-toggler {
  border-color: transparent;
}

.header-search-iconbar .form-wrapper {
  @apply bg-[#232323e6] w-full left-0 top-0 fixed overflow-hidden block h-[100vh] z-[999] opacity-0 invisible;
  transition: all 0.2s ease-in-out;
  cursor: url("../img/mfg-close.png"), pointer;
}
.header-search-iconbar .form-wrapper.show {
  @apply opacity-100 visible;
}
.header-search-iconbar .form-wrapper.show .search-form {
  @apply translate-y-0 cursor-auto;
}
.header-search-iconbar .form-wrapper .search-close {
  @apply text-black fixed top-[30px] right-[35px] overflow-visible cursor-pointer border-none block outline-none p-0 z-[1046] text-[30px] w-[44px] h-[44px] leading-[44px] xs:top-[5px] xs:right-[5px] font-light;
}
.header-search-iconbar .form-wrapper .search-form {
  @apply bg-white flex items-center justify-center h-0 absolute top-0 left-0 w-full -translate-y-full;
  height: 450px;
  transition: all 0.4s ease-in-out;
  cursor: auto;
}
.header-search-iconbar .form-wrapper .search-form .search-form-box {
  @apply w-[45%] md:w-[60%] xs:w-[90%] relative;
}
.header-search-iconbar .form-wrapper .search-form .search-form-box .search-input {
  @apply rounded-none text-[20px] font-light w-full pt-[10px] pr-[38px] pb-[10px] pl-[2px] leading-[40px] xs:leading-[30px] xs:text-[18px] xs:pt-[8px] xs:pr-[38px] xs:pb-[8px] xs:pl-[2px] text-darkgray;
  line-height: 40px;
}
.header-search-iconbar .form-wrapper .search-form .search-form-box input::placeholder {
  @apply text-darkgray;
}
.header-search-iconbar .form-wrapper .search-button {
  @apply cursor-pointer text-base mr-0 absolute right-0 z-0 text-darkgray outline-none;
  bottom: 19px;
  line-height: normal;
}
.header-search-iconbar .form-wrapper .search-button i {
  padding: 1px 6px;
}

.collapse {
  @apply visible;
}

.menu-modern {
  margin-left: -70vw;
  padding-right: 70vw;
}
@screen xs {
  .menu-modern {
    margin-left: -85vw;
    padding-right: 85vw;
  }
}

.mobile-menu header .smooth-scrollbar {
  overflow: visible !important;
}
@screen md {
  .mobile-menu header .smooth-scrollbar {
    overflow: hidden !important;
  }
}
.mobile-menu header .smooth-scrollbar .scroll-content {
  padding-right: 0px;
}

.toplogo-header .navbar-nav > .nav-item > .nav-link {
  padding: 25px 0;
}

