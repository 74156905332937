@tailwind base;

/* Vendors */
@import 'swiper/swiper-bundle.min.css';
@import "bootstrap/dist/css/bootstrap.min.css";
@import "atropos/atropos.css";
@import "react-18-image-lightbox/style.css";

/* Variable */
@import "./variable";

/* Core */
@import "./core/reset";
@import "./core/classes";
@import "./core/typography";

/* Components */
@import "./components/clients";
@import "./components/grid";
@import "./components/interactivebanners";
@import "./components/portfolio";
@import "./components/portfolio";
@import "./layouts/_footer.scss";
@import "./layouts/_header.scss";